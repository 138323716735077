// Firebase Dev Config
export const VITE_FIREBASECONFIG_DEV_APIKEY =
  import.meta.env.VITE_FIREBASECONFIG_DEV_APIKEY ?? "";
export const VITE_FIREBASECONFIG_DEV_AUTHDOMAIN =
  import.meta.env.VITE_FIREBASECONFIG_DEV_AUTHDOMAIN ?? "";
export const VITE_FIREBASECONFIG_DEV_PROJECTID =
  import.meta.env.VITE_FIREBASECONFIG_DEV_PROJECTID ?? "";
export const VITE_FIREBASECONFIG_DEV_STORAGEBUCKET =
  import.meta.env.VITE_FIREBASECONFIG_DEV_STORAGEBUCKET ?? "";
export const VITE_FIREBASECONFIG_DEV_MESSAGINGSENDERID =
  import.meta.env.VITE_FIREBASECONFIG_DEV_MESSAGINGSENDERID ?? "";
export const VITE_FIREBASECONFIG_DEV_APPID =
  import.meta.env.VITE_FIREBASECONFIG_DEV_APPID ?? "";
export const VITE_FIREBASECONFIG_DEV_MEASUREMENTID =
  import.meta.env.VITE_FIREBASECONFIG_DEV_MEASUREMENTID ?? "";

// Firebase Prod Config
export const VITE_FIREBASECONFIG_PROD_APIKEY =
  import.meta.env.VITE_FIREBASECONFIG_PROD_APIKEY ?? "";
export const VITE_FIREBASECONFIG_PROD_AUTHDOMAIN =
  import.meta.env.VITE_FIREBASECONFIG_PROD_AUTHDOMAIN ?? "";
export const VITE_FIREBASECONFIG_PROD_PROJECTID =
  import.meta.env.VITE_FIREBASECONFIG_PROD_PROJECTID ?? "";
export const VITE_FIREBASECONFIG_PROD_STORAGEBUCKET =
  import.meta.env.VITE_FIREBASECONFIG_PROD_STORAGEBUCKET ?? "";
export const VITE_FIREBASECONFIG_PROD_MESSAGINGSENDERID =
  import.meta.env.VITE_FIREBASECONFIG_PROD_MESSAGINGSENDERID ?? "";
export const VITE_FIREBASECONFIG_PROD_APPID =
  import.meta.env.VITE_FIREBASECONFIG_PROD_APPID ?? "";
export const VITE_FIREBASECONFIG_PROD_MEASUREMENTID =
  import.meta.env.VITE_FIREBASECONFIG_PROD_MEASUREMENTID ?? "";

// Stripe Config
export const VITE_STRIPE_PRICING_TABLE_ID_LOCAL =
  import.meta.env.VITE_STRIPE_PRICING_TABLE_ID_LOCAL ?? "";
export const VITE_STRIPE_PUBLISHABLE_KEY_LOCAL =
  import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_LOCAL ?? "";
export const VITE_STRIPE_PRICING_TABLE_ID_DEV =
  import.meta.env.VITE_STRIPE_PRICING_TABLE_ID_DEV ?? "";
export const VITE_STRIPE_PUBLISHABLE_KEY_DEV =
  import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_DEV ?? "";
export const VITE_STRIPE_PRICING_TABLE_ID_PROD =
  import.meta.env.VITE_STRIPE_PRICING_TABLE_ID_PROD ?? "";
export const VITE_STRIPE_PUBLISHABLE_KEY_PROD =
  import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_PROD ?? "";

// URL Checks with type safety
export const IS_LOCAL_BASE_URL = ["localhost", "192.168", "ngrok"].some(
  (d) => window?.location?.host?.includes(d) ?? false
);

export const IS_DEV_BASE_URL = ["aiter-ai-dev", "dev.aiter.io"].some(
  (d) => (window?.location?.host?.includes(d) ?? false) && !IS_LOCAL_BASE_URL
);

export const IS_PROD_BASE_URL = ["aiter.io"].some(
  (d) =>
    (window?.location?.host?.includes(d) ?? false) &&
    !IS_DEV_BASE_URL &&
    !IS_LOCAL_BASE_URL
);

// Stripe URLs
export const STRIPE_URL_MANAGE_PLAN_DEV =
  import.meta.env.VITE_STRIPE_MANAGE_PLAN_URL_DEV ?? "";
export const STRIPE_URL_MANAGE_PLAN_PROD =
  import.meta.env.VITE_STRIPE_MANAGE_PLAN_URL_PROD ?? "";

// Amplitude Keys
export const API_KEY_PROD = import.meta.env.VITE_AMPLITUDE_API_KEY_PROD ?? "";
export const API_KEY_DEV = import.meta.env.VITE_AMPLITUDE_API_KEY_DEV ?? "";
