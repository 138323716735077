import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

interface SectionButtonProps {
  icon: React.ElementType;
  name: string;
  isActive: boolean;
  onClick: () => void;
  rightIcon?: React.ReactNode;
  isLocked?: boolean;
}

const SectionButton: React.FC<SectionButtonProps> = ({ icon, name, isActive, onClick, rightIcon, isLocked = false }) => {
  return (
    <Button
      fullWidth
      onClick={onClick}
      sx={{
        justifyContent: 'flex-start',
        padding: 2,
        backgroundColor: 'transparent',
        color: isActive ? 'primary.main' : isLocked ? grey[500] : 'inherit',
        '&:hover': {
          backgroundColor: isLocked ? 'transparent' : 'primary.light',
        },
      }}>
      <Box display="flex" alignItems="center" width="100%">
        {React.createElement(icon, {
          sx: {
            color: isActive ? 'primary' : isLocked ? grey[500] : 'inherit',
          },
        })}
        <Typography
          variant={isActive ? 'button' : 'body1'}
          sx={{
            ml: 2,
            flexGrow: 1,
            textAlign: 'left',
            color: 'inherit',
            '&:hover': {
              color: isLocked ? grey[500] : 'primary.main',
            },
          }}>
          {name}
        </Typography>
        {rightIcon && <Box ml={1}>{rightIcon}</Box>}
      </Box>
    </Button>
  );
};

export default SectionButton;
