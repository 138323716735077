import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import 'material-symbols/rounded.css';
import '../../styles/icons.css';

export const IconInfo = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    info
  </Box>
);

export const IconTune = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    tune
  </Box>
);

export const IconRocketLaunchFilled = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded material-symbols-rounded-filled" sx={sx}>
    rocket_launch
  </Box>
);

export const IconLanguage = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    language
  </Box>
);

export const IconArrowDown = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    arrow_drop_down
  </Box>
);

export const IconPerson = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    person
  </Box>
);

export const IconLogout = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    logout
  </Box>
);

export const IconAddCircle = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    add_circle
  </Box>
);

export const IconClose = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    close
  </Box>
);

export const IconKeyboardArrowDown = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    keyboard_arrow_down
  </Box>
);

export const IconMoreHorizontal = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    more_horiz
  </Box>
);

export const IconArrowRight = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    arrow_right
  </Box>
);

export const IconMoreVert = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    more_vert
  </Box>
);

export const IconSummary = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    description
  </Box>
);

export const IconAds = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    ad_group
  </Box>
);

export const IconStrategy = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    strategy
  </Box>
);

export const IconSMM = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    share
  </Box>
);

export const IconSEO = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    manage_search
  </Box>
);

export const IconEmail = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    stacked_email
  </Box>
);

export const IconArticles = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    edit_note
  </Box>
);

export const IconBrand = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    label
  </Box>
);

export const IconPR = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    newsmode
  </Box>
);

export const IconFrameworks = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    mitre
  </Box>
);

export const IconVideoScripts = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    video_library
  </Box>
);

export const IconCopy = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    content_copy
  </Box>
);

export const IconReload = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    cached
  </Box>
);

export const IconNotes = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    notes
  </Box>
);

export const IconCheck = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    check
  </Box>
);

export const IconLock = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    lock
  </Box>
);

export const IconEditNote = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    edit_note
  </Box>
);

export const IconError = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box component="span" className="material-symbols-rounded" sx={sx}>
    error
  </Box>
);
