import { styled } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';

const Title = styled('h5')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 18,
  fontWeight: 600,
  marginTop: 24,
  textAlign: 'center', // Ensure the text is centered
}));

const Word = styled(motion.span)`
  display: inline-block;
  margin-right: 0.25em;
  white-space: nowrap;
`;

const Character = styled(motion.span)`
  display: inline-block;
`;

interface AnimatedWordProps {
  word: string;
  index: number;
}

const wordAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.03,
    },
  },
};

const characterAnimation = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.25 } },
};

const AnimatedWord: React.FC<AnimatedWordProps> = ({ word, index }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <Word ref={ref} initial="hidden" animate={controls} variants={wordAnimation} key={index}>
      {word.split('').map((char, charIndex) => (
        <Character key={charIndex} variants={characterAnimation}>
          {char}
        </Character>
      ))}
    </Word>
  );
};

interface AnimatedTextProps {
  text: string;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ text }) => {
  // Split the text into words and render a component for each word
  const words = text.split(' ').map((word, index) => <AnimatedWord word={word} index={index} key={index} />);

  return <Title>{words}</Title>;
};

export default AnimatedText;
