import {
  GoogleAuthProvider,
  linkWithPopup,
  onAuthStateChanged,
  signInAnonymously,
  signInWithCredential,
  User,
} from "firebase/auth";
import { finalizeCampaignCreation, register } from "./api";
import { firebaseAuth } from "./firebaseUtils";
import { store } from "./store";

export async function signOutUser() {
  const auth = await getFirebaseAuthAsync();
  await auth.signOut();
  window.location.reload();
}

export function isUserSignedIn(user?: User | null) {
  return !!user;
}

export function isUserAnonymous(user?: User | null) {
  return user?.isAnonymous;
}

export function isUserRegistered(user?: User | null) {
  return !!(user && user.email);
}

export async function isUserSignedInAsync() {
  const auth = await getFirebaseAuthAsync();
  return isUserSignedIn(auth.currentUser);
}

export async function isUserAnonymousAsync() {
  const auth = await getFirebaseAuthAsync();
  return isUserAnonymous(auth.currentUser);
}

export async function isUserRegisteredAsync() {
  const auth = await getFirebaseAuthAsync();
  return isUserRegistered(auth.currentUser);
}

export async function getCurrentUserAsync() {
  const auth = await getFirebaseAuthAsync();
  return auth.currentUser;
}

export async function getFirebaseAuthAsync() {
  await new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      firebaseAuth,
      (user) => {
        resolve(user); // Resolves the promise when the auth state is ready.
        unsubscribe(); // Unsubscribe the observer.
      },
      (error) => reject(error) // Rejects the promise if an error occurs.
    );
  });
  return firebaseAuth;
}

export async function registerAsync() {
  const auth = await getFirebaseAuthAsync();
  const googleProvider = new GoogleAuthProvider();

  if (auth.currentUser) {
    try {
      await linkWithPopup(auth.currentUser, googleProvider);
      const userId = auth.currentUser.uid;
      await register(userId);

      // Get campaign ID and session from Redux store
      const state = store.getState().appState;
      const selectedCampaign = state.selectedCampaign;
      const session = state.session;

      // If we have a selected campaign, finalize it
      if (selectedCampaign?.id) {
        await finalizeCampaignCreation(userId, selectedCampaign.id, session);
      }
    } catch (error: any) {
      if (error.code === "auth/credential-already-in-use") {
        const { oauthIdToken, oauthAccessToken } =
          error.customData._tokenResponse;
        const credential = GoogleAuthProvider.credential(
          oauthIdToken,
          oauthAccessToken
        );
        const userCredential = await signInWithCredential(auth, credential);
        const userId = userCredential.user.uid;
        await register(userId);

        // Same campaign finalization logic for existing users
        const state = store.getState().appState;
        const selectedCampaign = state.selectedCampaign;
        const session = state.session;

        if (selectedCampaign?.id) {
          await finalizeCampaignCreation(userId, selectedCampaign.id, session);
        }
      } else {
        console.error("registerWithPopupAsync: error", error);
        throw error;
      }
    }
  } else {
    console.error("linkWithPopup: auth.currentUser is null");
  }
}

export async function createAnonymousUserAsync() {
  const auth = await getFirebaseAuthAsync();

  const isSignedIn = await isUserSignedInAsync();
  const isAnonymous = await isUserAnonymousAsync();
  const isRegistered = await isUserRegisteredAsync();

  if (isRegistered || (isSignedIn && isAnonymous)) {
    return auth.currentUser;
  } else if (isSignedIn && isAnonymous) {
    return auth.currentUser;
  } else if (!isSignedIn) {
    return (await signInAnonymously(auth)).user;
  } else {
    console.error("signInUserAnonymously: unexpected state");
    return null;
  }
}

export async function hashValue(value: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
}
