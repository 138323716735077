import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, List, ListItem, ListItemText, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import imageManUpgrade from '../../icons/man-with-sigarett-upgrade.webp';
import { isUserRegisteredAsync, registerAsync } from '../../utils/auth';
import LoadingPopup from './LoadingPopup';

interface UpgradePopupDialogProps {
  open: boolean;
  onClose: () => void;
  onError?: (error: unknown) => void;
  title?: string;
  header?: string;
  featuresTitle?: string;
  features?: string[];
  footer?: string;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  '&::before': {
    content: '"•"',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const UpgradePopup: React.FC<UpgradePopupDialogProps> = ({ open, onClose, onError, title, header, featuresTitle, features: customFeatures, footer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleUpgrade = async () => {
    try {
      const isRegistered = await isUserRegisteredAsync();
      if (isRegistered) {
        navigate('/app/profile');
      } else {
        onClose();
        setIsLoggingIn(true);
        await registerAsync();
        navigate('/app/profile');
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  const defaultFeatures = [t('website.upgradePopup.default.features.feature1'), t('website.upgradePopup.default.features.feature2'), t('website.upgradePopup.default.features.feature4')];

  const displayFeatures = customFeatures || defaultFeatures;

  return (
    <>
      <Dialog
        PaperProps={{
          component: motion.div,
          initial: { y: '100%' },
          animate: { y: 0 },
          exit: { y: '100%' },
          transition: { type: 'spring', stiffness: 300, damping: 30 },
          sx: {
            maxHeight: 'calc(100vh - 104px)',
            marginTop: '52px',
            marginBottom: '52px',
            display: 'flex',
            flexDirection: 'column',
            width: 672,
          },
        }}
        open={open}
        onClose={onClose}
        maxWidth="tablet"
        fullWidth>
        <DialogTitle>
          <Typography variant="h2">{title || t('website.upgradePopup.default.title')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container size={{ mobile: 12, tablet: 6 }}>
            <Grid>
              <Typography variant="body1" sx={{ mb: 3 }}>
                {header || t('website.upgradePopup.default.header')}
              </Typography>

              <Typography variant="h6" fontWeight="bold">
                {featuresTitle || t('website.upgradePopup.default.features.title')}
              </Typography>
            </Grid>

            <Grid container justifyContent="space-between" size={12}>
              <Grid size={'grow'}>
                <List dense disablePadding sx={{ mx: 2 }}>
                  {displayFeatures.map((feature, index) => (
                    <StyledListItem key={index}>
                      <ListItemText primary={feature} />
                    </StyledListItem>
                  ))}
                </List>
              </Grid>
              <Grid display="flex" justifyContent="flex-end">
                <img src={imageManUpgrade} alt="Upgrade popup image" width={200} />
              </Grid>
            </Grid>

            <Box mt={3}>
              <Typography variant="body2" fontStyle="italic">
                {footer || t('website.upgradePopup.footer')}
              </Typography>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('website.upgradePopup.buttonCancel')}</Button>
          <Button
            onClick={handleUpgrade}
            variant="contained"
            color="success"
            sx={{
              color: 'white',
              '&:hover': {
                bgcolor: 'success.dark',
              },
            }}>
            {t('website.upgradePopup.buttonUpgrade')}
          </Button>
        </DialogActions>
      </Dialog>

      <LoadingPopup open={isLoggingIn} onClose={() => setIsLoggingIn(false)} title="Login" initialText="Login in progress..." isSimpleLoading={true} />
    </>
  );
};

export default UpgradePopup;
