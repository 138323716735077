import { Box, CircularProgress, Container, Grid2 as Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SectionButton from '../components/minicomponents/SectionButton';
import { IconError, IconLock, IconMoreVert } from '../components/minicomponents/icons';
import AlertPopup from '../components/popups/AlertPopup';
import InputPopup from '../components/popups/InputPopup';
import { parseDomainFromUrl } from '../utils/textUtils';

import { grey } from '@mui/material/colors';
import { TFunction } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CampaignSection from '../components/CampaignSection';
import BreadcrumbsNavigation from '../components/minicomponents/BreadcrumbsNavigation';
import RegisterPopup from '../components/popups/RegisterPopup';
import UpgradePopup from '../components/popups/UpgradePopup';
import { finalizeCampaignCreation, handleApiError as handleApiErrorUtil } from '../utils/api';
import { registerAsync } from '../utils/auth';
import { LOCKED_SECTIONS_PER_PLAN, SECTIONS } from '../utils/const';
import { deleteCampaign, updateCampaign, useListenCampaignChanges, useListenProductChanges } from '../utils/db';
import useBreakpoint, { useFirebaseLoginState } from '../utils/hooks';
import { setSelectedCampaign, setSelectedProduct } from '../utils/state';
import { RootState } from '../utils/store';
import { getTranslationKey } from '../utils/translationUtils';
import { CampaignSection as CampaignSectionType, Item, LoadingStatus, PlanCode, RequestType, ScreenWidth, SectionName } from '../utils/types';

const campaignNameLengthFormatted = (campaignName: string, currentBreakpoint: ScreenWidth) => {
  const breakpoints = {
    desktopWide: 100,
    desktop: 80,
    laptop: 60,
    tablet: 25,
    mobile: 20,
  };
  const maxLength = breakpoints[currentBreakpoint as keyof typeof breakpoints] || breakpoints.mobile;
  return campaignName.length > maxLength ? campaignName.substring(0, maxLength) + '...' : campaignName;
};

const createBreadcrumbItems = (productUrl: string, campaignName: string, currentBreakpoint: ScreenWidth, t: TFunction) => {
  const breadcrumbItems = [
    {
      name: t('app.screens.campaigns.title'),
      path: '/app/campaigns',
    },
    {
      name: (
        <Typography variant="h2" fontSize={24} color="text.primary" noWrap>
          {parseDomainFromUrl(productUrl)
            ? `${parseDomainFromUrl(productUrl)} | ${campaignNameLengthFormatted(campaignName, currentBreakpoint)}`
            : campaignNameLengthFormatted(campaignName, currentBreakpoint)}
        </Typography>
      ),
      path: '',
    },
  ];
  return breadcrumbItems;
};

function isSectionLocked(section: CampaignSectionType, userPlan?: PlanCode, isUserMigratedToCredits?: boolean): boolean {
  if (userPlan === 'not_registered') {
    return LOCKED_SECTIONS_PER_PLAN[userPlan].includes(section.name);
  } else if (isUserMigratedToCredits) {
    return false;
  } else if (!userPlan) {
    return true;
  } else {
    return LOCKED_SECTIONS_PER_PLAN[userPlan].includes(section.name);
  }
}

function buildSectionStatusIcon(section: CampaignSectionType, isLoading: boolean, isFailed: boolean, userPlan?: PlanCode, isUserMigratedToCredits?: boolean): React.ReactElement {
  if (isFailed) return <IconError sx={{ color: 'error.main', fontSize: 20, display: 'flex' }} />;
  if (isLoading) return <CircularProgress size={16} sx={{ display: 'flex', color: 'text.secondary' }} />;
  if (isSectionLocked(section, userPlan, isUserMigratedToCredits)) {
    return <IconLock sx={{ color: grey[500], fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }} />;
  }
  return <></>;
}

export const CampaignScreen = () => {
  // Redux/Router hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const state = useSelector((state: RootState) => state.appState);

  const creditsLeft = state?.subcription?.credits || 0;
  const userPlan = state?.subcription?.planCode;
  const isUserMigratedToCredits = !!state?.flags?.isUserMigratedToCredits;

  // URL/Auth state
  const campaignId = location.pathname.split('/').pop();
  const { isRegistered, userId } = useFirebaseLoginState();
  const currentBreakpoint: ScreenWidth = useBreakpoint();

  // Data fetching hooks
  const { campaign, isUnknownResourceError } = useListenCampaignChanges(campaignId);
  const { product } = useListenProductChanges(campaign?.product_id);

  // Local state hooks
  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
  const [inputDialogOpen, setInputDialogOpen] = useState(false);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isUpgradePopupOpen, setUpgradePopupOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<SectionName>(SectionName.Summary);
  const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Derived state
  const isMenuOpen = Boolean(anchorMenuEl);

  // Error handling callback
  const handleError = useCallback((error: unknown) => {
    handleApiErrorUtil({
      error,
      onSetUpgradePopupOpen: setUpgradePopupOpen,
      onSetErrorMessage: setError,
      onSetErrorAlertOpen: isOpen => {
        setIsAlertOpen(isOpen);
        if (!isOpen) setError(null);
      },
    });
  }, []);

  // Effects
  useEffect(() => {
    let redirectTimeout: NodeJS.Timeout;
    if (isUnknownResourceError) {
      redirectTimeout = setTimeout(() => {
        navigate('/app/campaigns');
      }, 100);
    }
    return () => {
      if (redirectTimeout) {
        clearTimeout(redirectTimeout);
      }
    };
  }, [isUnknownResourceError, navigate]);

  useEffect(() => {
    if (campaign) {
      dispatch(setSelectedCampaign(campaign));
    }
  }, [campaign, dispatch]);

  useEffect(() => {
    if (product) {
      dispatch(setSelectedProduct(product));
    }
  }, [product, dispatch]);

  useEffect(() => {
    const shouldFinalizeCampaign = () => {
      if (!campaign?.items || !isRegistered || !userId || !creditsLeft) return false;

      return campaign.items.length === 1 && campaign.items[0].type === RequestType.MARKETING_BRIEF;
    };

    const finalizeCampaignIfNeeded = async () => {
      if (shouldFinalizeCampaign()) {
        try {
          await finalizeCampaignCreation(userId!, campaign!.id!, state.session);
        } catch (error) {
          console.error('Error finalizing campaign:', error);
        }
      }
    };

    finalizeCampaignIfNeeded();
  }, [campaign?.items, isRegistered, userId, campaign?.id, state.session]);

  const handleSectionClick = useCallback((sectionName: SectionName, isEnabled: boolean, isRegistered: boolean) => {
    if (isEnabled) {
      setActiveSection(sectionName);
    } else {
      if (isRegistered) {
        setUpgradePopupOpen(true);
      } else {
        setIsRegisterPopupOpen(true);
      }
    }
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const handleRenameClick = () => {
    setInputDialogOpen(true);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true);
    handleMenuClose();
  };

  const handleRenameSubmit = async (newName: string) => {
    if (newName && campaign) {
      await updateCampaign(campaign.id!, { name: newName });
    }
    setInputDialogOpen(false);
  };

  const handleRenameDialogClose = () => {
    setInputDialogOpen(false);
  };

  const handleDeleteConfirmationSubmit = async () => {
    if (campaign) {
      try {
        await deleteCampaign(campaign.id!);
        navigate('/app/campaigns');
      } catch (error) {
        console.error('Error deleting campaign:', error);
      }
    }
    setDeleteConfirmationOpen(false);
  };

  const isSectionItemsLoading = (items?: Item[]): boolean => {
    return items?.some(item => item.status === LoadingStatus.LOADING) || false;
  };

  const isSectionItemsFailed = (items?: Item[]): boolean => {
    return items?.some(item => item.status === LoadingStatus.FAILED) || false;
  };

  const getCampaignSectionItems = (sectionName?: SectionName): Item[] => {
    if (sectionName && campaign) {
      const section = SECTIONS.find(section => section.name === sectionName);
      if (!section) return [];

      const sectionItems = section.tabs?.flatMap(tab => tab.items) || [];
      const tabItems = section.tabs?.flatMap(tab => tab.items) || [];
      const allItems = [...sectionItems, ...tabItems];

      return campaign.items?.filter(item => allItems.includes(item.type)) || [];
    }
    return [];
  };

  if (!campaign) return <></>;

  const handleRegisterPopupClose = () => {
    setIsRegisterPopupOpen(false);
  };

  const handleRegisterClicked = async () => {
    setIsRegisterPopupOpen(false);
    try {
      await registerAsync();
    } catch (error) {
      console.error('Registration error:', error);
    }
  };

  return (
    <Container maxWidth="desktop" sx={{ mt: 4, mb: 4 }}>
      {/* <ReleaseNotificationBanner sx={{ mt: -1 }} /> */}
      <Grid container gap={2}>
        {/* Breadcrumbs and menu */}
        <Grid container size={12} alignItems="center">
          <Grid size="grow">
            <BreadcrumbsNavigation items={createBreadcrumbItems(product?.url || '', campaign?.name || '', currentBreakpoint, t)} />
          </Grid>
          <Grid>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuClick} sx={{ color: 'text.primary' }}>
              <IconMoreVert />
            </IconButton>
            <Menu id="long-menu" anchorEl={anchorMenuEl} keepMounted open={isMenuOpen} onClose={handleMenuClose}>
              <MenuItem onClick={handleRenameClick}>{t('app.buttons.rename')}</MenuItem>
              <MenuItem onClick={handleDeleteClick}>{t('app.buttons.delete')}</MenuItem>
            </Menu>
          </Grid>
        </Grid>

        {/* Sidebar with sections */}
        <Grid
          container
          direction="row"
          size={12}
          sx={{
            bgcolor: 'background.default',
            borderRadius: '8px',
          }}>
          <Grid sx={{ p: 2 }}>
            {SECTIONS.map((section, index) => (
              <Box key={section.name}>
                <SectionButton
                  icon={section.icon}
                  name={t(`app.sectionsNames.${getTranslationKey(section.name)}`)}
                  isActive={activeSection === section.name}
                  isLocked={isSectionLocked(section, userPlan, isUserMigratedToCredits)}
                  rightIcon={buildSectionStatusIcon(
                    section,
                    isSectionItemsLoading(getCampaignSectionItems(section.name)),
                    isSectionItemsFailed(getCampaignSectionItems(section.name)),
                    userPlan,
                    isUserMigratedToCredits
                  )}
                  onClick={() => handleSectionClick(section.name, !isSectionLocked(section, userPlan, isUserMigratedToCredits), isRegistered)}
                />
              </Box>
            ))}
          </Grid>

          {/* Main section content */}
          <Grid container size="grow" sx={{ p: 3 }}>
            <CampaignSection sectionName={activeSection} />
          </Grid>
        </Grid>
      </Grid>

      {/* InputPopup for renaming campaign */}
      <InputPopup
        open={inputDialogOpen}
        onClose={handleRenameDialogClose}
        onSubmit={handleRenameSubmit}
        buttonText={t('app.buttons.rename')}
        dialogTitle={t('app.popups.rename.title')}
        dialogText={t('app.popups.rename.text')}
        inputValue={campaign?.name || ''}
      />

      {/* AlertPopup for delete confirmation */}
      <AlertPopup
        open={isDeleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onSubmit={handleDeleteConfirmationSubmit}
        buttonText={t('app.buttons.delete')}
        alertText={t('app.popups.delete.text')}
        title={t('app.popups.delete.title')}
        buttonColor="error.main"
      />

      {/* UpgradePopupDialog */}
      <UpgradePopup
        open={isUpgradePopupOpen}
        onClose={() => setUpgradePopupOpen(false)}
        onError={handleError}
        title={isRegistered ? t('website.upgradePopup.higherPlan.title') : undefined}
        header={isRegistered ? t('website.upgradePopup.higherPlan.header') : undefined}
        featuresTitle={isRegistered ? t('website.upgradePopup.higherPlan.features.title') : undefined}
        features={
          isRegistered
            ? [
                t('website.upgradePopup.higherPlan.features.feature1'),
                t('website.upgradePopup.higherPlan.features.feature2'),
                t('website.upgradePopup.higherPlan.features.feature3'),
                t('website.upgradePopup.higherPlan.features.feature4'),
              ]
            : undefined
        }
        footer={isRegistered ? t('website.upgradePopup.footer') : undefined}
      />

      {/* RegisterPopupDialog */}
      <RegisterPopup open={isRegisterPopupOpen} onClose={handleRegisterPopupClose} onRegisterClicked={handleRegisterClicked} />

      <AlertPopup
        open={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onSubmit={() => setIsAlertOpen(false)}
        alertText={error || ''}
        title={t('app.popups.error.title')}
        buttonText={t('app.buttons.ok')}
      />
    </Container>
  );
};
