import { Typography } from '@mui/material';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  err?: Error;
}

export class NetworkErrorBoundary extends Component<Props, State> {
  public state: State = {};

  public static getDerivedStateFromError(err: Error): State {
    // Update state so the next render will show the fallback UI
    return { err };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (!!this.state.err) {
      return (
        <Typography variant="h4" component="h2" gutterBottom>
          Rendering Error:
          <Typography variant="body1" component="p" gutterBottom>
            {this.state.err.message}
          </Typography>
        </Typography>
      );
    }

    return this.props.children;
  }
}
