import { createTheme, ThemeProvider } from '@mui/material/styles';

import { ReactNode } from 'react';
import fontLabGrotesqueBold from '../fonts/LabGrotesque-Bold.ttf';
import fontLabGrotesqueMedium from '../fonts/LabGrotesque-Medium.ttf';
import fontLabGrotesqueRegular from '../fonts/LabGrotesque-Regular.ttf';
import { colors } from './colors';

// Define custom breakpoints
export const breakpoints = {
  values: {
    mobile: 0,
    tablet: 640,
    laptop: 1024,
    desktop: 1200,
    desktopWide: 1500,
  },
};

// Create the theme with custom breakpoints and other configurations
const theme = createTheme({
  breakpoints: breakpoints,
  palette: {
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
    },
    secondary: {
      main: colors.secondary.main,
      light: colors.secondary.light,
    },
    background: {
      default: colors.primary.text,
    },
    text: {
      primary: colors.primary.dark,
      secondary: colors.input.text.secondary,
    },
    error: {
      main: colors.creativity.spicy,
    },
  },
  typography: {
    fontFamily: 'LabGrotesque, Arial, sans-serif',
    h1: {
      fontFamily: 'LabGrotesque-Bold, Arial, sans-serif',
      fontSize: 36,
      fontWeight: 700,
      color: colors.primary.dark,
    },
    h2: {
      fontFamily: 'LabGrotesque-Regular, Arial, sans-serif',
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '26px',
      color: colors.primary.dark,
    },
    h3: {
      fontFamily: 'LabGrotesque-Medium, Arial, sans-serif',
      color: colors.primary.dark,
    },
    h6: {
      fontFamily: 'LabGrotesque-Medium, Arial, sans-serif',
      fontSize: 14,
      fontWeight: 400,
      color: colors.primary.dark,
    },
    body1: {
      fontFamily: 'LabGrotesque-Regular, Arial, sans-serif',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      color: colors.primary.dark,
    },
    body2: {
      fontFamily: 'LabGrotesque-Regular, Arial, sans-serif',
      fontSize: 14,
      fontWeight: 400,
      color: colors.primary.dark,
      lineHeight: '20px',
    },
    caption: {
      fontFamily: 'LabGrotesque-Medium, Arial, sans-serif',
      fontSize: 12,
      fontWeight: 400,
      color: colors.primary.dark,
    },
    button: {
      fontFamily: 'LabGrotesque-Bold, Arial, sans-serif',
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 700,
      color: colors.primary.dark,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'LabGrotesque';
          font-style: normal;
          font-weight: 400;
          src: url(${fontLabGrotesqueRegular}) format('truetype');
        }
        @font-face {
          font-family: 'LabGrotesque';
          font-style: normal;
          font-weight: 500;
          src: url(${fontLabGrotesqueMedium}) format('truetype');
        }
        @font-face {
          font-family: 'LabGrotesque';
          font-style: normal;
          font-weight: 700;
          src: url(${fontLabGrotesqueBold}) format('truetype');
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.primary.dark,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondary.light,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          backgroundColor: colors.secondary.light,
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.primary.light}`,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.input.border.hover}`, // Hover border color
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.input.border.active}`, // Focused border color
          },
          '& .MuiOutlinedInput-input': {
            '&::placeholder': {
              color: colors.input.text.accent,
              opacity: 1,
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          border: 0,
          height: 40,
          color: colors.primary.dark,
          '&.MuiButton-contained': {
            backgroundColor: colors.primary.main,
            color: '#fff',
            border: 'none',
            '&:hover': {
              backgroundColor: colors.primary.dark,
            },
            '&:active': {
              backgroundColor: colors.primary.dark,
            },
            '&.Mui-disabled': {
              backgroundColor: colors.primary.light,
              color: colors.secondary.text,
            },
          },
          '&.Mui-disabled': {
            background: colors.primary.light,
            color: colors.secondary.text,
          },
          '&:hover': {
            backgroundColor: colors.primary.light,
            border: 0,
          },
          '&:active': {
            backgroundColor: colors.custom.bgPressed,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colors.primary.light,
          },
        },
      },
    },
  },
});

export function ApplyTheme({ children }: { children: ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
