import { Box, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { trackShowTooltipManualMode } from '../utils/analytics';
import { Mode } from '../utils/types';
import { IconInfo } from './minicomponents/icons';
import { InfoTooltipView } from './minicomponents/InfoTooltipView';

const BoxContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: 8,
  height: 40,
  width: 300,
  display: 'flex',
  alignItems: 'center',
}));

const BoxChild = styled(Box)(({ theme }) => ({
  borderRadius: 7,
  margin: '2px',
  height: 'calc(100% - 4px)',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  transition: 'background-color 0.3s ease, color 0.3s ease',
}));

export function SwitchComponent({ mode, onModeChanged }: { mode: Mode; onModeChanged: (mode: Mode) => void }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <BoxContainer>
      <BoxChild
        onClick={() => onModeChanged('url')}
        sx={{
          cursor: mode === 'url' ? 'default' : 'pointer',
          bgcolor: mode === 'url' ? 'white' : theme.palette.primary.light,
          transition: 'background-color 0.1s ease-in-out',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography variant={'button'}>{t('app.buttons.url')}</Typography>
        </Box>
      </BoxChild>
      <BoxChild
        onClick={() => onModeChanged('manual')}
        sx={{
          cursor: mode === 'url' ? 'pointer' : 'default',
          bgcolor: mode === 'url' ? theme.palette.primary.light : 'white',
          transition: 'background-color 0.1s ease-in-out',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography variant={'button'}>{t('app.buttons.manualInput')}</Typography>
          <Box sx={{ flexGrow: 0, pl: 0.5, mt: 0.4 }}>
            <InfoTooltipView onTooltipOpenCallback={trackShowTooltipManualMode} text={t('app.labels.switchTooltipText')} icon={<IconInfo />} />
          </Box>
        </Box>
      </BoxChild>
    </BoxContainer>
  );
}
