import { FormControl, FormHelperText, InputLabel, OutlinedInput, SxProps, Theme } from '@mui/material';

interface FieldComponentProps {
  fieldLabel?: string;
  fieldValue: string;
  onEdit?: (value: string) => void;
  placeholder?: string;
  error?: string | null;
  sx?: SxProps<Theme>;
}

export function FieldComponent({ fieldLabel, fieldValue, onEdit, placeholder, error, sx }: FieldComponentProps) {
  const value = fieldValue?.replace(/^[ \t]+/gm, '') ?? '';

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      error={!!error}
      sx={{
        backgroundColor: 'secondary.light',
      }}>
      {fieldLabel && <InputLabel>{fieldLabel}</InputLabel>}
      <OutlinedInput
        label={fieldLabel}
        sx={{
          minHeight: '120px',
          alignItems: 'start',
          '& .MuiOutlinedInput-input::placeholder': {
            color: 'inherit',
            opacity: 1,
          },
          ...sx,
        }}
        placeholder={placeholder}
        value={value}
        onChange={onEdit ? e => onEdit(e.target.value) : undefined}
        multiline
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
