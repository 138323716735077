import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles"; // Correct theme import
import { useMemo } from "react";
import { useSigninCheck } from "reactfire";
import { isUserAnonymous, isUserRegistered, isUserSignedIn } from "./auth";

export function useFirebaseLoginState() {
  const { status, error, data } = useSigninCheck();

  const user = data?.user;
  const userId = user?.uid;

  const isSignedIn = isUserSignedIn(user);
  const isRegistered = isUserRegistered(user);
  const isAnonymous = isUserAnonymous(user);
  const isAuthStateLoading = status === "loading";

  return {
    status,
    user,
    userId,
    isRegistered,
    isSignedIn,
    isAnonymous,
    isAuthStateLoading,
    error,
  };
}

export function useIsPaidUserStatus(subscriptionStatus?: string) {
  return useMemo(() => {
    return (
      subscriptionStatus === "paid_active" ||
      subscriptionStatus === "paid_active_limited" ||
      subscriptionStatus === "canceled_active"
    );
  }, [subscriptionStatus]);
}

const useBreakpoint = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse(); // Breakpoints in descending order

  // Find the first breakpoint that matches the current screen size
  return (
    keys.reduce((output: any, key: any) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "mobile"
  );
};

export default useBreakpoint;
