import { Button, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { registerAsync } from '../utils/auth';

const RegisterScreen: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRegister = async () => {
    try {
      setError(null);
      await registerAsync();
      navigate('/app/campaigns');
    } catch (error) {
      console.error(error);
      setError(t('app.errors.registrationFailed'));
    }
  };

  useEffect(() => {
    handleRegister();
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '50vh' }}>
      <Grid size={6} textAlign="center">
        {!error && <CircularProgress size={40} color="inherit" />}
        {error && (
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid>
              <Typography variant="h4" color="error">
                {t('app.screens.register.error')}
              </Typography>
            </Grid>
            <Grid>
              <Typography color="error">{error}</Typography>
            </Grid>
            <Grid>
              <Button variant="contained" onClick={handleRegister}>
                {t('app.buttons.retry')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default RegisterScreen;
