const figmaColors = {
  text: {
    primary: "#00134C", // also named as active in Figma
    secondary: "#00134CA6", // 65% opacity
    accentPressed: "#0B1BDE",
    hint: "#868EA5",
    white: "#FFFFFF", // using as background.white too
    black: "#000000",
  },
  accent: {
    main: "#3D4BF5",
    hover: "#4F5CF8",
  },
  background: {
    accent: "#F1F1FB", // also named as raised in Figma
    accentPressed: "#2433EB",
    grey: "#F8F8FA", // also named as 0 in Figma
    pressed: "#E2E2F5",
    campaigns: "#F8F8FA",
    hoverCampaignDetailsIcon: "#0000000D", // 5% opacity of black
  },
  input: {
    background: "#FCFCFE",
    border: "#E9E9F2", // Merged with splitLine.main
    placeholder: "#B7B7D8", // Merged with icon.active
  },
};

export const colors = {
  primary: {
    light: figmaColors.background.accent,
    main: "#0040FF",
    dark: figmaColors.text.primary,
    text: figmaColors.text.white,
  },
  secondary: {
    light: figmaColors.input.background,
    main: "#A57DD3",
    text: figmaColors.text.secondary,
  },
  creativity: {
    conservative: "#1565C0",
    standard: figmaColors.text.primary,
    spicy: "#C62828",
    crazy: "#EF6C00",
  },
  custom: {
    campaigns: figmaColors.background.campaigns,
    bgGrey: figmaColors.background.grey,
    bgPressed: figmaColors.background.pressed,
    hoverCampaignDetailsIcon: figmaColors.background.hoverCampaignDetailsIcon,
  },
  input: {
    text: {
      primary: figmaColors.text.primary,
      secondary: figmaColors.text.secondary,
      accent: figmaColors.accent.main,
    },
    border: {
      hover: figmaColors.input.placeholder,
      active: figmaColors.accent.main,
    },
  },
};
