import * as Sentry from "@sentry/react";
import { snakeCase } from "lodash";
import {
  amplitudeAddOpenAITokenConsumption,
  amplitudeSetUserSignedIn,
  amplitudeSetUserSignedOut,
  amplitudeTrackEvent,
} from "./amplitude";
import { gaTrackEvent } from "./ga";
import { Mode, OpenAiTokenConsumptionType } from "./types";

export function trackEvent(
  eventName: string,
  eventCategory?: string,
  eventLabel?: string,
  eventValue?: string | number | boolean,
  isRawEvent?: boolean
) {
  const eventCategoryFinal = !!eventCategory
    ? isRawEvent
      ? eventCategory
      : snakeCase(eventCategory?.toLowerCase())
    : undefined;
  const eventLabelFinal = !!eventLabel
    ? isRawEvent
      ? eventLabel
      : snakeCase(eventLabel?.toLowerCase())
    : undefined;
  const eventNameFinal = snakeCase(eventName?.toLowerCase());

  gaTrackEvent(eventNameFinal, eventCategoryFinal, eventLabelFinal, eventValue);
  amplitudeTrackEvent(
    eventNameFinal,
    eventCategoryFinal,
    eventLabelFinal,
    eventValue
  );
}

export function trackSentryError(
  error: string,
  customParams: { [key: string]: any } = {}
) {
  console.error(`trackSentryError: ${error}`, customParams);
  Sentry.captureException(new Error(error), {
    tags: customParams,
  });
}

export function trackSubmitWebsiteURL(url?: string) {
  trackEvent("submit_website_url", url, undefined, undefined, true);
}

export function trackOpenNavigationMenu() {
  trackEvent("open_navigation_menu");
}

export function trackClickHowItWorksHeader() {
  trackEvent("click_how_it_works", "header");
}

export function trackClickBenefitsHeader() {
  trackEvent("click_benefits", "header");
}

export function trackClickFeaturesHeader() {
  trackEvent("click_features", "header");
}

export function trackClickLinkedinHeader() {
  trackEvent("click_linkedin", "header");
}

export function trackClickTwitterHeader() {
  trackEvent("click_twitter", "header");
}

export function trackClickFacebookHeader() {
  trackEvent("click_facebook", "header");
}

export function trackGetStartedHeader() {
  trackEvent("click_get_started", "header");
}

export function trackSelectUrlMode() {
  trackEvent("select_url_mode");
}

export function trackSelectManualMode() {
  trackEvent("select_manual_mode");
}

export function trackShowTooltipManualMode() {
  trackEvent("show_tooltip_manual_mode");
}

export function trackClickGetResultsButtonBody(mode: Mode) {
  trackEvent("click_get_results", "body", mode);
}

export function trackSelectCreativityLevel(level: string) {
  trackEvent("select_creativity_level", level);
}

export function trackOutputLanguageChange(lang: string) {
  trackEvent("select_output_language", lang);
}

export function trackSelectTab(tabName: string) {
  trackEvent("select_tab", tabName);
}

export function trackClickCopy(fieldName?: string) {
  trackEvent("click_copy", fieldName);
}

export function trackClickRegenerate(fieldName?: string) {
  trackEvent("click_regenerate", fieldName);
}

export function trackClickExpand(fieldName?: string) {
  trackEvent("click_expand", fieldName);
}

export function trackClickCollapse(fieldName?: string) {
  trackEvent("click_collapse", fieldName);
}

export function trackClickBannerTheresanaiforthat(tabName: string) {
  trackEvent("click_banner_theresanaiforthat", `tab_${tabName}`);
}

export function trackClickBannerTheresanaiforthatFooter() {
  trackEvent("click_banner_theresanaiforthat", `footer`);
}

export function trackClickBannerTheresanaiforthatBadgesSection() {
  trackEvent("click_banner_theresanaiforthat", `badges_section`);
}

export function trackClickBannerProductHunt() {
  trackEvent("click_banner_product_hunt", "badges_section");
}

export function trackClickGetAdsAndIdeas1() {
  trackEvent("click_get_ads_and_ideas_1");
}

export function trackClickGetAdsAndIdeas2() {
  trackEvent("click_get_ads_and_ideas_2");
}

export function trackClickFeaturesCarousel(featureName: string) {
  trackEvent("click_features_carousel", featureName);
}

export function trackExpandFaqSection(name: string) {
  trackEvent("expand_faq_section", name);
}

export function trackClickLinkedinFooter() {
  trackEvent("click_linkedin", "footer");
}

export function trackClickTwitterFooter() {
  trackEvent("click_twitter", "footer");
}

export function trackClickFacebookFooter() {
  trackEvent("click_facebook", "footer");
}

export function trackSignIn(userId: string, isRegistered: boolean) {
  trackEvent("sign_in", undefined, undefined, userId, false);
  if (userId) {
    amplitudeSetUserSignedIn(userId, isRegistered);
  } else {
    console.warn("trackSignIn: userId is undefined");
  }
}

export function trackSignOutHeader(userId?: string) {
  trackEvent("sign_out", "header", undefined, userId, false);
  if (userId) {
    amplitudeSetUserSignedOut(userId);
  } else {
    console.warn("trackSigOut: userId is undefined");
  }
}

export function trackSignOutProfile(userId?: string) {
  trackEvent("sign_out", "profile", undefined, userId, false);
  if (userId) {
    amplitudeSetUserSignedOut(userId);
  } else {
    console.warn("trackSigOut: userId is undefined");
  }
}

export function trackOpenAiTokenConsumption(
  fieldCode: string,
  consumptionType: OpenAiTokenConsumptionType,
  tokens?: number
) {
  trackEvent("open_ai_token_consumption", fieldCode, consumptionType, tokens);
  amplitudeAddOpenAITokenConsumption(consumptionType, tokens || 0);
}

export function trackClickPrivacyPolicy() {
  trackEvent("click_privacy_policy", "footer");
}

export function trackClickTermsOfService() {
  trackEvent("click_terms_of_service", "footer");
}

export function trackClickCookiePolicy() {
  trackEvent("click_cookie_policy", "footer");
}

export function trackClickProfile() {
  trackEvent("click_profile");
}

export function trackClickLogoutProfile() {
  trackEvent("click_logout", "profile");
}

export function trackClickLogoutCancelProfile() {
  trackEvent("click_logout_cancel", "profile");
}

export function trackStripePurchaseSuccesful(checkoutSessionId?: string) {
  trackEvent("stripe_purchase_successful_2", checkoutSessionId);
}

export function trackShowSupportChat(userId: string, isShown: boolean) {
  trackEvent("show_support_chat", "crisp", userId, isShown, true);
}
