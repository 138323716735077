import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../utils/colors';
import { getTranslationKey } from '../utils/translationUtils';
import { TabName } from '../utils/types';
import { IconAddCircle, IconError } from './minicomponents/icons';

interface Tab {
  name: TabName;
  isRequireGeneration: boolean;
  isLoading: boolean;
  isError: boolean;
}

interface CampaignSectionTabsProps {
  tabs: Tab[];
  activeTab: TabName;
  onTabChange: (event: React.SyntheticEvent, newValue: TabName) => void;
}

export const CampaignSectionTabs: React.FC<CampaignSectionTabsProps> = ({ tabs, activeTab, onTabChange }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const buildTabStatusIcon = (tab: Tab): React.ReactNode => {
    if (tab.isError) return <IconError sx={{ color: 'error.main', fontSize: 16, display: 'flex', ml: 1 }} />;
    if (tab.isLoading) return <CircularProgress size={12} sx={{ display: 'flex', ml: 1, color: 'text.secondary' }} />;
    if (tab.isRequireGeneration)
      return (
        <IconAddCircle
          sx={{
            marginLeft: 0.5,
            fontSize: 24,
            verticalAlign: 'middle',
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        />
      );
    return <></>;
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '36px' }}>
      <Tabs
        value={activeTab}
        onChange={onTabChange}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{
          style: { background: theme.palette.primary.main },
        }}
        sx={{ minHeight: '36px', height: '36px' }}>
        {tabs.map(tab => (
          <Tab
            key={tab.name}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '32px',
                  padding: '0 8px',
                  borderRadius: '6px',
                  transition: 'background-color 0.3s',
                  ...(tab.name !== activeTab && {
                    '&:hover': {
                      backgroundColor: theme.palette.primary.light,
                    },
                  }),
                  '&:active': {
                    backgroundColor: colors.custom.bgPressed,
                  },
                }}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.primary.dark,
                    ...(tab.name === activeTab && {
                      color: theme.palette.primary.main,
                    }),
                    ...(tab.isRequireGeneration && {
                      background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }),
                  }}>
                  {t(`app.tabsNames.${getTranslationKey(tab.name)}`)}
                </Typography>
                {buildTabStatusIcon(tab)}
              </Box>
            }
            value={tab.name}
            sx={{
              textTransform: 'none',
              height: '36px',
              minHeight: '36px',
              minWidth: 'unset',
              padding: '4px 0',
              mr: 1,
              '&.Mui-selected': {
                color: theme.palette.primary.main,
              },
            }}
            disableRipple
          />
        ))}
      </Tabs>
    </Box>
  );
};
