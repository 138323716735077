import firebase from "firebase/compat/app";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import "moment/locale/uk"; // Import Ukrainian locale
import i18n from "./translations";

export const updatedAtNowFirebase = () => firebase.firestore.Timestamp.now();

export const firebaseTimestampToDate = (timestamp: any): Date | null => {
  if (!timestamp) return null;
  try {
    return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
  } catch (e) {
    console.error(
      `Error converting firebase timestamp to date: ${e} for timestamp: ${timestamp}`
    );
    return null;
  }
};

export const dateToString = (date?: Date | null) => {
  if (!date) return null;
  return moment(date).format("MMM D, YYYY");
};

export function formatDateToExactTime(date: Date): string {
  // Use moment's localized format instead of manual locale handling
  return moment(date).format("LLLL");
}

export function formatCustomDistanceToNow(date: Date): string {
  const now = moment();
  const momentDate = moment(date);
  const diffMinutes = now.diff(momentDate, "minutes");
  const isToday = momentDate.isSame(now, "day");
  const isYesterday = momentDate.isSame(now.clone().subtract(1, "day"), "day");

  // Case 1: Less than 1 minute
  if (diffMinutes < 1) {
    return i18n.t("app.dateTime.justNow");
  }

  // Case 2: Less than 1 hour
  if (diffMinutes < 60) {
    // Use fromNow() to get the relative time in minutes
    return momentDate.fromNow();
  }

  // Case 3: Today
  if (isToday) {
    const hoursAgo = momentDate.fromNow();
    return i18n.t("app.dateTime.today", {
      time: hoursAgo,
    });
  }

  // Case 4: Yesterday
  if (isYesterday) {
    return i18n.t("app.dateTime.yesterday", {
      time: momentDate.format("LT"),
    });
  }

  // Case 5: Before yesterday
  return momentDate.format("lll");
}
