import { ReportProblemRounded, StarRounded } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled, Tooltip, Typography } from '@mui/material';
import { green, yellow } from '@mui/material/colors';
import { useEffect, useRef, useState } from 'react';
import { SentryMuiWrapper } from './minicomponents';

const InfoIcon = styled(InfoOutlinedIcon)({
  width: 24,
  height: 24,
  color: 'secondary.light',
});

const AlertIcon = styled(ReportProblemRounded)({
  width: 24,
  height: 24,
  color: yellow[800],
});

const StarIcon = styled(StarRounded)({
  width: 24,
  height: 24,
  color: green[500],
});

export function InfoTooltipView({
  text,
  onTooltipOpenCallback,
  icon = <InfoIcon />,
  forceOpen,
  isDisabled,
  onClick,
  autoCloseDelay,
}: {
  text?: React.ReactElement | string | false | React.ReactNode;
  onTooltipOpenCallback?: () => void;
  icon?: React.ReactElement;
  forceOpen?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  autoCloseDelay?: number;
}) {
  const [isOpen, setOpen] = useState<boolean>(!!forceOpen);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isDisabled) {
      setOpen(!!forceOpen || isHovered);
    }
  }, [forceOpen, isDisabled, isHovered]);

  useEffect(() => {
    if (isOpen && autoCloseDelay) {
      timerRef.current = setTimeout(() => {
        setOpen(false);
      }, autoCloseDelay);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isOpen, autoCloseDelay]);

  function handleTooltipClose() {
    if (!isDisabled && !forceOpen) {
      setOpen(false);
      setIsHovered(false);
    }
  }

  function handleTooltipToggle(event: React.MouseEvent) {
    event.stopPropagation();
    if (isDisabled) return;
    if (!forceOpen) {
      setIsHovered(!isHovered);
    }
    if (onClick) onClick();
    if (!isOpen && onTooltipOpenCallback) onTooltipOpenCallback();
  }

  function handleMouseEnter() {
    if (!isDisabled && !forceOpen) {
      setIsHovered(true);
    }
  }

  function handleMouseLeave() {
    if (!isDisabled && !forceOpen) {
      setIsHovered(false);
    }
  }

  const displayText = text ? (
    <Typography variant="caption" color="primary.contrastText" textAlign={'center'}>
      {text}
    </Typography>
  ) : null;

  const content = (
    <Box onClick={handleTooltipToggle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span>{icon}</span>
    </Box>
  );

  if (!text) {
    return content;
  }

  return (
    <SentryMuiWrapper onClickAway={handleTooltipClose}>
      <Tooltip
        title={displayText}
        open={isDisabled ? false : isOpen}
        arrow
        placement="top"
        disableFocusListener
        disableHoverListener={isDisabled || forceOpen}
        disableTouchListener={isDisabled || forceOpen}>
        {content}
      </Tooltip>
    </SentryMuiWrapper>
  );
}
