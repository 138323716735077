import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid2 as Grid, TextField, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { green } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProfilePicButton } from '../components/minicomponents/GoogleAuthProfilePicButton';
import { InfoTooltipView } from '../components/minicomponents/InfoTooltipView';
import bg from '../icons/Hexagon.svg';
import { trackSentryError } from '../utils/analytics';
import { redeemAppsumoCode } from '../utils/api';
import { useFirebaseLoginState } from '../utils/hooks';

export default function AppSumoScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const isTabletUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'));

  const { isRegistered, user, isAuthStateLoading } = useFirebaseLoginState();

  const [code, setCode] = React.useState('');
  const [isButtonStateLoading, setIsButtonStateLoading] = React.useState(false);
  const [codeError, setCodeError] = React.useState<string | undefined>();

  const profilePageUrl = '/profile' + window.location.search;

  const onSubmitCodeClick = () => {
    setIsButtonStateLoading(true);
    setCodeError(undefined);

    redeemAppsumoCode(user?.uid, code)
      .then(req => {
        setIsButtonStateLoading(false);
        navigate(profilePageUrl);
      })
      .catch(error => {
        trackSentryError(`Error redeeming code`, {
          code: code,
          error: error.message,
        });
        setIsButtonStateLoading(false);
        setCodeError(JSON.parse(error.message).message);
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Grid
        container
        justifyContent="center"
        textAlign="center"
        gap={4}
        sx={{
          backgroundColor: '#F1F1FB',
          width: isTabletUp ? 600 : '90%',
          borderRadius: 2,
          p: 4,
        }}>
        <Grid size={12}>
          <Typography variant={isTabletUp ? 'h3' : 'h5'}>{t('website.appsumoPage.title')}</Typography>
        </Grid>
        <Grid size={12}>
          <Typography variant={'h6'}>{t('website.appsumoPage.subtitle_1')}</Typography>
          <Typography variant={'body1'}>{t('website.appsumoPage.subtitle_2')}</Typography>
        </Grid>
        <Grid size={12}>
          <TextField
            label={t('website.profile.appsumoCode')}
            variant="outlined"
            sx={{ minWidth: isTabletUp ? 300 : '100%' }}
            placeholder={t('website.profile.appsumoCodeExample')}
            value={code}
            error={!!codeError}
            helperText={codeError}
            onChange={e => setCode(e.target.value)}
          />
        </Grid>
        {isRegistered ? (
          <Grid size={12} sx={{ mt: -2 }}>
            <LoadingButton
              onClick={onSubmitCodeClick}
              variant="contained"
              color={'success'}
              loading={isButtonStateLoading}
              size={'large'}
              sx={{
                ...(isButtonStateLoading && {
                  background: `${green[100]} !important`,
                }),
              }}>
              {t('website.appsumoPage.submitCode')}
            </LoadingButton>
          </Grid>
        ) : (
          <Grid size={12}>
            {!code ? (
              <InfoTooltipView
                text={'Enter the code to enable login'}
                icon={
                  <Button disabled sx={{ opacity: 0.5 }}>
                    <GoogleAuthProfilePicButton />
                  </Button>
                }
              />
            ) : (
              <GoogleAuthProfilePicButton />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
