import * as React from 'react';
import {
  IS_LOCAL_BASE_URL,
  IS_PROD_BASE_URL,
  VITE_STRIPE_PRICING_TABLE_ID_DEV,
  VITE_STRIPE_PRICING_TABLE_ID_LOCAL,
  VITE_STRIPE_PRICING_TABLE_ID_PROD,
  VITE_STRIPE_PUBLISHABLE_KEY_DEV,
  VITE_STRIPE_PUBLISHABLE_KEY_LOCAL,
  VITE_STRIPE_PUBLISHABLE_KEY_PROD,
} from '../utils/env';
import { useFirebaseLoginState } from '../utils/hooks';

const selectPricingTableId = () => {
  if (IS_LOCAL_BASE_URL) return VITE_STRIPE_PRICING_TABLE_ID_LOCAL;
  if (IS_PROD_BASE_URL) return VITE_STRIPE_PRICING_TABLE_ID_PROD;
  return VITE_STRIPE_PRICING_TABLE_ID_DEV;
};

const selectPublishableKey = () => {
  if (IS_LOCAL_BASE_URL) return VITE_STRIPE_PUBLISHABLE_KEY_LOCAL;
  if (IS_PROD_BASE_URL) return VITE_STRIPE_PUBLISHABLE_KEY_PROD;
  return VITE_STRIPE_PUBLISHABLE_KEY_DEV;
};

// Declare the custom element
declare global {
  interface HTMLElementTagNameMap {
    'stripe-pricing-table': HTMLElement;
  }
}

// Props interface using kebab-case for Stripe's custom element
interface StripePricingTableProps {
  'pricing-table-id': string;
  'publishable-key': string;
  'client-reference-id'?: string;
  'customer-email'?: string;
}

// Create a React wrapper component for the Stripe pricing table
function StripePricingTable(props: StripePricingTableProps) {
  React.useEffect(() => {
    // Ensure the custom element is defined
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return React.createElement('stripe-pricing-table', props);
}

export function PricingPage() {
  const { userId, user } = useFirebaseLoginState();
  const pricingTableId = selectPricingTableId();
  const publishableKey = selectPublishableKey();

  return <StripePricingTable pricing-table-id={pricingTableId} publishable-key={publishableKey} client-reference-id={userId} customer-email={user?.email || undefined} />;
}
