import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INITIAL_STATE, OUTPUT_LANGUAGE_DATA } from "./const";
import {
  Campaign,
  Creativity,
  Mode,
  OutputLanguageData,
  Product,
  StripeUser,
  SubscriptionInfo,
} from "./types";

export const stateSlice = createSlice({
  name: "appState",
  initialState: INITIAL_STATE,
  reducers: {
    updateStripeUser: (state, action: PayloadAction<StripeUser>) => {
      state.stripe = action.payload;
    },
    updateSubscriptionInfo: (
      state,
      action: PayloadAction<SubscriptionInfo>
    ) => {
      state.subcription = action.payload;
    },
    updateSession: (state, action: PayloadAction<string>) => {
      state.session = action.payload;
    },
    setOutputLanguageManuallySelected: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOutputLanguageManuallySelected = action.payload;
    },
    setMode: (state, action: PayloadAction<Mode>) => {
      state.mode = action.payload;
    },
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setCreativity: (state, action: PayloadAction<Creativity>) => {
      state.creativity = action.payload;
    },
    setOutputLanguage: (state, action: PayloadAction<OutputLanguageData>) => {
      state.outputLanguage = action.payload;
    },
    setManualDescription: (state, action: PayloadAction<string>) => {
      state.prompt = action.payload;
    },
    resetInputDataSection: (state) => {
      state.url = "";
      state.prompt = "";
      state.creativity = "standard";
      state.outputLanguage = OUTPUT_LANGUAGE_DATA[0];
      state.mode = "url";
    },
    setSelectedCampaign: (state, action: PayloadAction<Campaign | null>) => {
      state.selectedCampaign = action.payload;
    },
    setSelectedProduct: (state, action: PayloadAction<Product | null>) => {
      state.selectedProduct = action.payload;
    },
    updateFlags: (
      state,
      action: PayloadAction<{ isUserMigratedToCredits: boolean }>
    ) => {
      state.flags = action.payload;
    },
  },
});

export const {
  updateStripeUser,
  updateSubscriptionInfo,
  updateSession,
  setOutputLanguageManuallySelected,
  setMode,
  setUrl,
  setCreativity,
  setOutputLanguage,
  setManualDescription,
  resetInputDataSection,
  setSelectedCampaign,
  setSelectedProduct,
  updateFlags,
} = stateSlice.actions;

export const stateReducer = stateSlice.reducer;
