import { Link as MuiLink, LinkProps as MuiLinkProps, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Updated import
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { trackClickCookiePolicy, trackClickPrivacyPolicy, trackClickTermsOfService } from '../utils/analytics';

interface LinkComponentProps extends MuiLinkProps {
  to: string;
  replace?: boolean;
}

const LinkComponent: React.FC<LinkComponentProps> = ({ children, to, replace, ...props }) => {
  return (
    <MuiLink
      component={RouterLink}
      to={to}
      replace={replace}
      {...props}
      sx={{
        opacity: '90%',
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' },
      }}>
      <Typography variant="body2" component="span" sx={{ color: 'primary.main' }}>
        {children}
      </Typography>
    </MuiLink>
  );
};

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      size={12}
      justifyContent="center"
      sx={{
        // mt: { mobile: '20%', tablet: '10%' },
        width: '100%',
        p: { mobile: 0, tablet: 8 },
        py: { mobile: 4, tablet: 8 },
      }}>
      <Grid
        container
        size={{ mobile: 12, tablet: 10, laptop: 7 }}
        gap={{ mobile: 4, tablet: 2 }}
        direction={{ mobile: 'column', tablet: 'row' }}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}>
        <Grid container size={{ mobile: 12, tablet: 'grow' }} direction="column" gap={1}>
          <Grid>
            <Typography variant="body1" sx={{ opacity: 0.6 }}>
              {t('website.footer.copyright')}
            </Typography>
          </Grid>
          <Grid container gap={2} justifyContent={'center'}>
            <LinkComponent
              to={'/app/privacy-policy'}
              target={'_blank'}
              onClick={() => {
                trackClickPrivacyPolicy();
              }}>
              {t('website.footer.privacyPolicy')}
            </LinkComponent>
            <LinkComponent
              to={'/app/terms-and-conditions'}
              target={'_blank'}
              onClick={() => {
                trackClickTermsOfService();
              }}>
              {t('website.footer.termsOfService')}
            </LinkComponent>
            <LinkComponent
              to={'/app/cookie-policy'}
              target={'_blank'}
              onClick={() => {
                trackClickCookiePolicy();
              }}>
              {t('website.footer.cookiePolicy')}
            </LinkComponent>
          </Grid>
          <Grid>
            <LinkComponent to={'mailto:hello@aiter.io'} target={'_blank'}>
              hello@aiter.io
            </LinkComponent>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
