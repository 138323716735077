import { Box, Link, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import workDesktopAnimation from '../icons/work-desktop.json';

const DesktopOnlyScreen = () => {
  const { t } = useTranslation();

  // Get current domain for the link
  const currentDomain = window.location.origin;

  // Split text into paragraphs and handle link replacement
  const text = t('app.screens.desktopOnly.text', { domain: currentDomain })
    .split('\n\n')
    .map((paragraph, index) => {
      if (paragraph.includes(currentDomain)) {
        const parts = paragraph.split(currentDomain);
        return (
          <Typography
            key={index}
            variant="body1"
            sx={{
              textAlign: 'center',
              maxWidth: 500,
              mx: 4,
              mb: 0,
              color: 'text.secondary',
            }}>
            {parts[0]}
            <Link href={currentDomain} color="primary" underline="hover">
              {currentDomain}
            </Link>
            {parts[1]}
          </Typography>
        );
      }
      return (
        <Typography
          key={index}
          variant="body1"
          sx={{
            textAlign: 'center',
            maxWidth: 500,
            mx: 4,
            mb: index < 1 ? 2 : 0,
            color: 'text.secondary',
          }}>
          {paragraph}
        </Typography>
      );
    });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        bgcolor: 'background.default',
        p: 3,
      }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: 400,
          height: 'auto',
          mb: 4,
        }}>
        <Lottie animationData={workDesktopAnimation} loop={true} style={{ width: '100%', height: 'auto' }} />
      </Box>
      <Typography
        variant="h4"
        component="h1"
        sx={{
          mb: 2,
          textAlign: 'center',
          fontWeight: 'bold',
        }}>
        {t('app.screens.desktopOnly.title')}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          mb: 3,
          textAlign: 'center',
          color: 'text.secondary',
          fontWeight: 'medium',
        }}>
        {t('app.screens.desktopOnly.subtitle')}
      </Typography>
      {text}
    </Box>
  );
};

export default DesktopOnlyScreen;
