import * as amplitude from "@amplitude/analytics-browser";
import { Identify } from "@amplitude/analytics-browser";
import {
  API_KEY_ENV,
  IS_DEVELOPMENT_ENV,
  PROPERTY_ENV,
  USER_PROPERTY_CREDITS_USED,
  USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_INPUT,
  USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_OUTPUT,
  USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_TOTAL,
  USER_PROPERTY_PLAN_NAME,
  USER_PROPERTY_REGENERATIONS_USED,
  USER_PROPERTY_SIGN_IN,
} from "./const";
import { OpenAiTokenConsumptionType } from "./types";

const ampInstance = amplitude.createInstance();
ampInstance.init(API_KEY_ENV, undefined, {
  instanceName: PROPERTY_ENV,
  logLevel: IS_DEVELOPMENT_ENV
    ? amplitude.Types.LogLevel.Verbose
    : amplitude.Types.LogLevel.Warn,
  defaultTracking: true,
});

export function isAmplitudeUserIdSet() {
  return !!ampInstance.getUserId();
}

export function amplitudeTrackEvent(
  eventName: string,
  eventCategory?: string,
  eventLabel?: string,
  eventValue?: string | number | boolean
) {
  ampInstance.track({
    event_type: eventName,
    event_properties: {
      env: PROPERTY_ENV,
      event_name: eventName,
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
    },
  });
}

export function amplitudeSetUserSignedIn(
  userId: string,
  isRegistered: boolean
) {
  ampInstance.setUserId(userId);
  const setSignedInProporty = new Identify().set(
    USER_PROPERTY_SIGN_IN,
    isRegistered
  );
  ampInstance.identify(setSignedInProporty);
}

export function amplitudeSetUserSignedOut(userId: string) {
  ampInstance.setUserId(userId);
  ampInstance.identify(new Identify().set(USER_PROPERTY_SIGN_IN, false));
  ampInstance.reset();
}

export function setCreditsUsed(creditsUsed: number) {
  ampInstance.identify(
    new Identify().set(USER_PROPERTY_CREDITS_USED, creditsUsed)
  );
}

export function addRegenerationsUsed() {
  ampInstance.identify(new Identify().add(USER_PROPERTY_REGENERATIONS_USED, 1));
}

export function setPlanName(planName: string) {
  ampInstance.identify(new Identify().set(USER_PROPERTY_PLAN_NAME, planName));
}

export function amplitudeAddOpenAITokenConsumption(
  consumptionType: OpenAiTokenConsumptionType,
  tokens: number
) {
  switch (consumptionType) {
    case "input":
      ampInstance.identify(
        new Identify().add(
          USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_INPUT,
          tokens
        )
      );
      break;
    case "output":
      ampInstance.identify(
        new Identify().add(
          USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_OUTPUT,
          tokens
        )
      );
      break;
    case "total":
      ampInstance.identify(
        new Identify().add(
          USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_TOTAL,
          tokens
        )
      );
      break;
    default:
      throw new Error(`Unknown consumptionType: ${consumptionType}`);
  }
}
