import { Box, IconButton, MenuItem, Select, styled } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconArrowDown, IconLanguage } from './icons';

interface LanguageOption {
  code: string;
  name: string;
}

const languageOptions: LanguageOption[] = [
  { code: 'en', name: 'languageName.en' },
  { code: 'uk', name: 'languageName.uk' },
];

const DropDownSelect = styled(Select)(({ theme }) => ({
  // color: theme.palette.secondary.main,
  '& .MuiSelect-select': {
    padding: 8,
  },
  '& .MuiInput-input:focus': {
    backgroundColor: 'transparent',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 6,
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  '&.MuiInput-underline:before, &.MuiInput-underline:after': {
    display: 'none',
  },
}));

const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const isValidLanguage = languageOptions.some(option => option.code === i18n.language);
    if (!isValidLanguage) {
      const defaultLanguage = languageOptions[0].code;
      i18n.changeLanguage(defaultLanguage);
    }
  }, [i18n]);

  const handleChange = useCallback(
    (event: any) => {
      const newLanguage = event.target.value as string;
      i18n.changeLanguage(newLanguage);
    },
    [i18n]
  );

  const handleIconClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DropDownSelect
      value={i18n.language}
      onChange={handleChange}
      open={open}
      onClose={handleClose}
      onOpen={() => setOpen(true)}
      size="small"
      inputProps={{ 'aria-label': 'language' }}
      variant="standard"
      IconComponent={(props: any) => (
        <IconButton
          onClick={handleIconClick}
          sx={{
            padding: 0,
            transition: 'color 0.2s',
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme => theme.palette.primary.main,
            },
          }}>
          <IconArrowDown {...props} sx={{ fontSize: 20, ml: -2 }} />
        </IconButton>
      )}
      renderValue={(value: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconLanguage sx={{ mr: 1, fontSize: 20 }} />
          {t(languageOptions.find(lang => lang.code === value)?.name ?? '')}
        </Box>
      )}
      MenuProps={{
        PaperProps: {
          sx: {
            '& .MuiMenuItem-root': {
              '&.Mui-selected': {
                backgroundColor: 'transparent',
              },
              '&:hover': {
                backgroundColor: (theme: any) => theme.palette.primary.light,
                color: (theme: any) => theme.palette.primary.main,
              },
            },
          },
        },
      }}>
      {languageOptions.map(({ code, name }) => (
        <MenuItem value={code} key={code}>
          {t(name)}
        </MenuItem>
      ))}
    </DropDownSelect>
  );
};

export default LanguageSwitcher;
