import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../utils/store';
import { IconRocketLaunchFilled } from './icons';
import { GradientTypography } from './minicomponents';

export function RequestsCounter() {
  const state = useSelector((state: RootState) => state.appState);
  const creditsLeft = Number(state?.subcription?.credits || 0);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{
        cursor: 'pointer',
        height: 40,
        px: 2,
        borderRadius: '6px',
        '&:hover': {
          bgcolor: 'primary.light',
        },
      }}
      onClick={() => navigate('/app/profile')}>
      <IconRocketLaunchFilled
        sx={{
          mr: 1,
          background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      />
      <GradientTypography variant={'body1'}>{t('website.creditsLeftCounter.credits', { creditsLeft: creditsLeft, count: creditsLeft })}</GradientTypography>
    </Box>
  );
}
