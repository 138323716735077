// src/components/AlertPopup.tsx

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AlertPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  buttonText: string;
  alertText: string;
  title: string;
  buttonColor?: string;
}

const AlertPopup: React.FC<AlertPopupProps> = ({ open, onClose, onSubmit, buttonText, alertText, title, buttonColor = 'primary.main' }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-popup-title" aria-describedby="alert-popup-description" maxWidth="tablet" fullWidth>
      <DialogTitle id="alert-popup-title">
        <Typography variant="h2">
          {title} {/* Use title prop here */}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-popup-description">
          <Typography
            variant="body1"
            color={'error'}
            sx={{
              whiteSpace: 'pre-line',
              fontFamily: 'inherit',
            }}>
            {alertText}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color={'primary'} autoFocus sx={{ color: buttonColor }}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertPopup;
