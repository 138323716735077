import { filter, maxBy } from "lodash";
import { PLANS_APPSUMO } from "./const";
import { AppsumoPlan } from "./types";

export const getPlanByNumberOfCodesRedeemed = (
  numberOfCodes: number
): AppsumoPlan | undefined => {
  return maxBy(
    filter(PLANS_APPSUMO, (plan) => plan.minCodesToRedeem <= numberOfCodes),
    "minCodesToRedeem"
  );
};
