import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface InputPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (newName: string) => void;
  buttonText: string;
  dialogTitle: string;
  dialogText: string;
  inputValue: string;
}

const InputPopup: React.FC<InputPopupProps> = ({ open, onClose, onSubmit, buttonText, dialogTitle, dialogText, inputValue }) => {
  const [value, setValue] = useState(inputValue);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      setValue(inputValue);
      setError('');
    }
  }, [open, inputValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (newValue.length < 3) {
      setError(t('app.labels.MustBeAtLeast3CharactersLong'));
    } else {
      setError('');
    }
  };

  const handleSubmit = () => {
    if (value.length >= 3) {
      onSubmit(value);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="tablet" fullWidth>
      <DialogTitle id="input-popup-title">
        <Typography variant="h2">{dialogTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="input-popup-description">
          <Typography variant="body1">{dialogText}</Typography>
        </DialogContentText>
        <TextField autoFocus margin="dense" id="name" label="Campaign Name" type="text" fullWidth variant="outlined" value={value} onChange={handleChange} error={!!error} helperText={error} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('app.buttons.cancel')}</Button>
        <Button onClick={handleSubmit} color="primary" autoFocus sx={{ color: 'primary.main' }} disabled={value.length < 3}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputPopup;
