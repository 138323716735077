import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "./firebaseUtils";

export function gaTrackEvent(
  eventName: string,
  eventCategory?: string,
  eventLabel?: string,
  eventValue?: string | number | boolean
) {
  logEvent(firebaseAnalytics, eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
    value: eventValue,
  });
}
