import LockIcon from '@mui/icons-material/Lock';
import { Box, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerAsync } from '../../utils/auth';
import { colors } from '../../utils/colors';
import { useFirebaseLoginState, useIsPaidUserStatus } from '../../utils/hooks';
import { RootState } from '../../utils/store';
import { Creativity } from '../../utils/types';
import { InfoTooltipView } from './InfoTooltipView';

interface CreativityLevelProps {
  initType: Creativity;
  onSelectedCreativityLvlChange: (lvl: Creativity) => void;
}

const getColorByType = (type: Creativity): string => {
  return colors.creativity[type] || colors.primary.dark;
};

const ToggleButtonStyled = styled(ToggleButton)<{ creativity: Creativity }>(({ theme, creativity }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 36,
  backgroundColor: theme.palette.primary.light,
  color: getColorByType(creativity),
  transition: 'background-color 0.3s ease, color 0.3s ease',
  padding: theme.spacing(1, 2),
  borderRadius: 8,
  border: 0,
  '&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    border: 0,
  },
  '&.Mui-disabled': {
    opacity: 0.3,
    color: getColorByType(creativity),
    backgroundColor: 'transparent',
    border: 0,
  },
  '&.MuiToggleButtonGroup-middleButton': {
    borderRadius: 8,
    border: 0,
  },
  '&.MuiToggleButtonGroup-firstButton': {
    borderRadius: 8,
    border: 0,
  },
  '&.MuiToggleButtonGroup-lastButton': {
    borderRadius: 8,
    border: 0,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: theme.palette.primary.light,
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 2,
  height: 36,
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
}));

export const CreativityLevel: React.FC<CreativityLevelProps> = ({ initType, onSelectedCreativityLvlChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isRegistered } = useFirebaseLoginState();

  const state = useSelector((state: RootState) => state.appState);
  const isPaidUser = useIsPaidUserStatus(state?.subcription?.status);

  const [selectedCreativity, setSelectedCreativity] = useState<Creativity>(initType);

  useEffect(() => {
    if (!isPaidUser && initType !== 'standard') {
      setSelectedCreativity('standard');
      onSelectedCreativityLvlChange('standard');
    }
  }, [isPaidUser, initType, onSelectedCreativityLvlChange]);

  const handleCreativityChange = (_: React.MouseEvent<HTMLElement>, newCreativity: Creativity | null) => {
    if (newCreativity !== null) {
      if (isPaidUser) {
        setSelectedCreativity(newCreativity);
        onSelectedCreativityLvlChange(newCreativity);
      }
    }
  };

  const handleLockedButtonClick = async () => {
    try {
      if (isRegistered) {
        navigate('/app/profile');
      } else {
        await registerAsync();
      }
    } catch (error) {
      console.error(`CreativityLevel: registration failed: ${error}`);
    }
  };

  const renderToggleButton = (type: Creativity) => {
    const isLocked = !isPaidUser && type !== 'standard';

    const buttonContent = (
      <span style={{ cursor: 'pointer' }} onClick={isLocked ? handleLockedButtonClick : undefined}>
        <ToggleButtonStyled value={type} aria-label={type} creativity={type} disabled={isLocked}>
          {t(`app.labels.${type}Creativity`)}
          {isLocked && <LockIcon sx={{ ml: 1, fontSize: 12, color: 'inherit' }} />}
        </ToggleButtonStyled>
      </span>
    );

    return (
      <InfoTooltipView
        key={type}
        text={
          isLocked && (
            <div
              dangerouslySetInnerHTML={{
                __html: !isRegistered ? t('app.labels.creativityUpgradeRegistration') : t('app.labels.creativityUpgradePayment'),
              }}
            />
          )
        }
        icon={buttonContent}
        autoCloseDelay={3000}
      />
    );
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
        {t('app.labels.creativityLabel')}
      </Typography>
      <BoxContainer>
        <StyledToggleButtonGroup value={selectedCreativity} exclusive onChange={handleCreativityChange} aria-label="creativity level">
          {(['conservative', 'standard', 'spicy', 'crazy'] as const).map(renderToggleButton)}
        </StyledToggleButtonGroup>
      </BoxContainer>
    </Box>
  );
};
