import { Box, useMediaQuery, useTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppSumoScreen from '../screens/AppSumoScreen';
import { CampaignScreen } from '../screens/CampaignScreen';
import { CampaignsListScreen } from '../screens/CampaignsListScreen';
import DesktopOnlyScreen from '../screens/DesktopOnlyScreen';
import ProfileScreen from '../screens/ProfileScreen';
import RegisterScreen from '../screens/RegisterScreen';
import CookiePolicy from '../screens/static/CookiePolicy';
import PrivacyPolicy from '../screens/static/PrivacyPolicy';
import TermsAndConditions from '../screens/static/TermsAndConditions';
import ThankYouPageScreen from '../screens/ThankYouPageScreen';
import { isAmplitudeUserIdSet, setCreditsUsed, setPlanName } from '../utils/amplitude';
import { trackShowSupportChat, trackSignIn } from '../utils/analytics';
import { invalidateCredits } from '../utils/api';
import { createAnonymousUserAsync } from '../utils/auth';
import { colors } from '../utils/colors';
import { DEV_LOGIN_PASS_CODE_INSERTED, DEV_LOGIN_PASS_CODE_SHA_256_ENCODED, IS_DEVELOPMENT_ENV } from '../utils/const';
import { hideCrispChat, showCrispChat } from '../utils/crispUtils';
import { firebaseTimestampToDate } from '../utils/dateUtils';
import { useListenUserChanges, useUserCodes } from '../utils/db';
import { useFirebaseLoginState } from '../utils/hooks';
import { updateFlags, updateSession, updateStripeUser, updateSubscriptionInfo } from '../utils/state';
import '../utils/translations';
import { PlanCode, SubscriptionStatus } from '../utils/types';
import DevLogin from './DevLogin';
import Footer from './Footer';
import Header from './Header';

const sessionId = 'session_' + moment().format('YYYY-MM-DD_HH-mm-ss');

const App: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallerThanDesktop = useMediaQuery(theme.breakpoints.down('laptop'));
  const { isRegistered, user, userId } = useFirebaseLoginState();
  const signInExecuted = useRef(false);
  const { dbUser } = useListenUserChanges(userId);
  const appSumoCodes = useUserCodes(user?.uid);

  // Check dev login
  const isDevLoginValid = () => {
    if (!IS_DEVELOPMENT_ENV) return true;
    const storedCode = Cookies.get(DEV_LOGIN_PASS_CODE_INSERTED);
    return storedCode === DEV_LOGIN_PASS_CODE_SHA_256_ENCODED;
  };

  useEffect(() => {
    if (!signInExecuted.current) {
      createAnonymousUserAsync();
      signInExecuted.current = true;
    }

    dispatch(updateSession(sessionId));
  }, []);

  useEffect(() => {
    if (!!userId) {
      invalidateCredits(userId, sessionId);
    }
  }, [userId]);

  useEffect(() => {
    if (!!userId) {
      if (!isAmplitudeUserIdSet()) {
        trackSignIn(userId, isRegistered);
      }
    }
  }, [isRegistered]);

  useEffect(() => {
    if (!!user) {
      Sentry.setUser({
        id: userId,
        email: user?.email || undefined,
        username: user?.displayName || undefined,
      });
    } else {
      Sentry.getGlobalScope().clear();
    }
  }, [user]);

  useEffect(() => {
    if (dbUser?.stripe) {
      dispatch(
        updateStripeUser({
          amountTotal: dbUser?.stripe?.amount_total,
          cancelAtPeriodEnd: dbUser?.stripe?.cancel_at_period_end,
          cancelAt: dbUser?.stripe?.cancel_at,
          currencyCode: dbUser?.stripe?.currency_code,
          customerId: dbUser?.stripe?.customer_id,
          planId: dbUser?.stripe?.plan_id,
          planInterval: dbUser?.stripe?.plan_interval,
          subscriptionName: dbUser?.stripe?.subscription_name,
        })
      );
      setPlanName(dbUser?.stripe?.subscription_name);
    }
  }, [JSON.stringify(dbUser?.stripe)]);

  useEffect(() => {
    if (dbUser?.subscription) {
      const data = {
        status: dbUser?.subscription?.status as SubscriptionStatus,
        credits: dbUser?.subscription?.credits,
        planCode: dbUser?.subscription?.plan_code as PlanCode,
        dateStart: moment(firebaseTimestampToDate(dbUser?.subscription?.start_date)).toDate(),
        dateEnd: moment(firebaseTimestampToDate(dbUser?.subscription?.end_date)).toDate(),
        dateCycleEnd: moment(firebaseTimestampToDate(dbUser?.subscription?.cycle_end_date)).toDate(),
        dateCycleStart: moment(firebaseTimestampToDate(dbUser?.subscription?.cycle_start_date)).toDate(),
      };
      setCreditsUsed(dbUser?.subscription?.credits);
      dispatch(updateSubscriptionInfo(data));
      dispatch(updateFlags({ isUserMigratedToCredits: dbUser?.flags?.is_migrated_to_credits }));
    }
  }, [JSON.stringify(dbUser?.subscription)]);

  useEffect(() => {
    // user is logged in and has a subscription
    if (appSumoCodes?.length || (!!dbUser?.subscription?.status && dbUser?.subscription?.status !== 'freemium' && !!userId)) {
      showCrispChat();
      trackShowSupportChat(`${userId}`, true);
    } else {
      hideCrispChat();
      trackShowSupportChat(`${userId}`, false);
    }
  }, [dbUser?.subscription?.status, userId]);

  if (IS_DEVELOPMENT_ENV && !isDevLoginValid()) {
    return <DevLogin />;
  }

  if (isSmallerThanDesktop) {
    return <DesktopOnlyScreen />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: window.location.pathname.includes('/campaigns') ? colors.custom.campaigns : 'background.default',
      }}>
      <Header />
      <main style={{ flex: 1 }}>
        <Routes>
          {/* Main app routes */}
          <Route
            path="/app/*"
            element={
              <Routes>
                {/* Main app routes */}
                <Route index element={<Navigate replace to="/app/campaigns" />} />
                <Route path="register" element={<RegisterScreen />} />
                <Route path="thankyou" element={<ThankYouPageScreen />} />
                <Route path="appsumo" element={<AppSumoScreen />} />
                <Route path="campaigns" element={<CampaignsListScreen />} />
                <Route path="campaigns/:campaignId" element={<CampaignScreen />} />
                <Route path="profile" element={<ProfileScreen />} />

                {/* Static policy pages */}
                <Route path="cookie-policy" element={<CookiePolicy />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms-and-conditions" element={<TermsAndConditions />} />

                {/* Catch any unknown paths under /app and redirect to /app/campaigns */}
                <Route path="*" element={<Navigate replace to="/app/campaigns" />} />
              </Routes>
            }
          />
          {/* Redirect root and all other unknown paths to /app/campaigns */}
          <Route path="/" element={<Navigate replace to="/app/campaigns" />} />
          <Route path="*" element={<Navigate replace to="/app/campaigns" />} />
        </Routes>
      </main>
      <Footer />
    </Box>
  );
};

export default App;
