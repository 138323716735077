import {
  IconAds,
  IconArticles,
  IconBrand,
  IconEmail,
  IconFrameworks,
  IconPR,
  IconSEO,
  IconSMM,
  IconStrategy,
  IconSummary,
  IconVideoScripts,
} from "../components/minicomponents/icons";
import {
  API_KEY_DEV,
  API_KEY_PROD,
  IS_DEV_BASE_URL,
  IS_LOCAL_BASE_URL,
  IS_PROD_BASE_URL,
  STRIPE_URL_MANAGE_PLAN_DEV,
  STRIPE_URL_MANAGE_PLAN_PROD,
} from "./env";
import {
  AppsumoPlan,
  CampaignSection,
  OutputLanguageData,
  PlanCode,
  RequestType,
  SectionName,
  State,
  TabName,
} from "./types";

export const SECTIONS: CampaignSection[] = [
  {
    name: SectionName.Summary,
    icon: IconSummary,
    tabs: [
      {
        name: TabName.Summary,
        items: [RequestType.MARKETING_BRIEF],
      },
    ],
  },
  {
    name: SectionName.Ads,
    icon: IconAds,
    tabs: [
      {
        name: TabName.Facebook,
        items: [
          RequestType.FACEBOOK_AD_TEXT_IMG_IDEAS,
          RequestType.FACEBOOK_NEED_SPECIFIC_ADS,
          RequestType.FACEBOOK_BARRIERS,
        ],
      },
      {
        name: TabName.Google,
        items: [
          RequestType.GOOGLE_ADS_TEXT,
          RequestType.GOOGLE_NEED_SPECIFIC_ADS,
          RequestType.GOOGLE_ADS_BARRIERS,
          RequestType.GOOGLE_ADS_DISPLAY,
        ],
      },
      {
        name: TabName.Instagram,
        items: [
          RequestType.INSTAGRAM_AD_TEXT_IMG_IDEAS,
          RequestType.INSTAGRAM_NEED_SPECIFIC_ADS,
          RequestType.INSTAGRAM_BARRIERS,
        ],
      },
      {
        name: TabName.LinkedIn,
        items: [
          RequestType.LINKEDIN_AD_TEXT_IMG_IDEAS,
          RequestType.LINKEDIN_NEED_SPECIFIC_ADS,
          RequestType.LINKEDIN_BARRIERS,
        ],
      },
      {
        name: TabName.TikTok,
        items: [RequestType.TIKTOK_VIDEO],
      },
    ],
  },
  {
    name: SectionName.Strategy,
    icon: IconStrategy,
    tabs: [
      {
        name: TabName.Barriers,
        items: [RequestType.STRATEGY_BARRIERS],
      },
      {
        name: TabName.NeedsJTBD,
        items: [RequestType.STRATEGY_JOBS_TO_BE_DONE],
      },
      {
        name: TabName.Persona,
        items: [RequestType.STRATEGY_CUSTOMER_PERSONA],
      },
      {
        name: TabName.ValuePropositionCanvas,
        items: [RequestType.STRATEGY_VALUE_PROPOSITION_CANVAS],
      },
      {
        name: TabName.ValueProposition,
        items: [RequestType.STRATEGY_VALUE_PROPOSITION],
      },
      {
        name: TabName.Positioning,
        items: [RequestType.STRATEGY_POSITIONING],
      },
      {
        name: TabName.BusinessModel,
        items: [RequestType.STRATEGY_BUSINESS_MODEL_CANVAS],
      },
      {
        name: TabName.DecisionJourney,
        items: [RequestType.STRATEGY_CONSUMER_DECISION_JOURNEY],
      },
    ],
  },
  {
    name: SectionName.SMM,
    icon: IconSMM,
    tabs: [
      {
        name: TabName.Facebook,
        items: [RequestType.FACEBOOK_POSTS, RequestType.FACEBOOK_POST_IDEAS],
      },
      {
        name: TabName.Instagram,
        items: [
          RequestType.INSTAGRAM_CAPTIONS,
          RequestType.INSTAGRAM_POST_IDEAS,
          RequestType.INSTAGRAM_JOKES_AND_STORIES,
          RequestType.INSTAGRAM_HASHTAG,
        ],
      },
      {
        name: TabName.LinkedIn,
        items: [RequestType.LINKEDIN_POSTS, RequestType.LINKEDIN_POST_IDEAS],
      },
      {
        name: TabName.TikTok,
        items: [
          RequestType.TIKTOK_CONTENT_STRATEGY,
          RequestType.TIKTOK_HASHTAGS,
          RequestType.TIKTOK_BIO,
        ],
      },
    ],
  },
  {
    name: SectionName.SEO,
    icon: IconSEO,
    tabs: [
      {
        name: TabName.CommonKeywords,
        items: [RequestType.SEO_COMMON_KEYWORDS],
      },
      {
        name: TabName.LongTailKeywords,
        items: [RequestType.SEO_LONG_TAIL_KEYWORDS],
      },
      {
        name: TabName.MetaTitleDescription,
        items: [RequestType.SEO_META_TITLE_AND_DESCRIPTION],
      },
    ],
  },
  {
    name: SectionName.Email,
    icon: IconEmail,
    tabs: [
      {
        name: TabName.EmailSubject,
        items: [RequestType.EMAIL_SUBJECT],
      },
      {
        name: TabName.PromotionalEmail,
        items: [RequestType.EMAIL_PROMOTIONAL],
      },
      {
        name: TabName.EmailMarketingTopics,
        items: [RequestType.EMAIL_MARKETING_TOPICS],
      },
    ],
  },
  {
    name: SectionName.Articles,
    icon: IconArticles,
    tabs: [
      {
        name: TabName.ContentPlan,
        items: [RequestType.BLOG_IDEAS],
      },
      {
        name: TabName.PromoArticle,
        items: [RequestType.BLOG_PROMO_ARTICLE],
      },
      {
        name: TabName.FAQ,
        items: [RequestType.BLOG_FAQ],
      },
      {
        name: TabName.SemanticCore,
        items: [RequestType.BLOG_KEYWORDS],
      },
    ],
  },
  {
    name: SectionName.Brand,
    icon: IconBrand,
    tabs: [
      {
        name: TabName.BrandGuide,
        items: [RequestType.BRAND_GUIDE],
      },
    ],
  },
  {
    name: SectionName.PR,
    icon: IconPR,
    tabs: [
      {
        name: TabName.CampaignIdeas,
        items: [RequestType.PR_CAMPAIGN_IDEAS],
      },
      {
        name: TabName.GuestArticle,
        items: [RequestType.PR_GUEST_ARTICLE],
      },
      {
        name: TabName.PRArticleIdeas,
        items: [RequestType.PR_ARTICLE_IDEAS],
      },
    ],
  },
  {
    name: SectionName.FrameworksAndIdeas,
    icon: IconFrameworks,
    tabs: [
      {
        name: TabName.MarketingIdeas,
        items: [RequestType.STRATEGY_MARKETING_IDEAS],
      },
      {
        name: TabName.STEPPS,
        items: [RequestType.FRAMEWORKS_STEPPS],
      },
      {
        name: TabName.SevenP,
        items: [RequestType.FRAMEWORKS_7P_OF_MARKETING],
      },
      {
        name: TabName.SWOT,
        items: [RequestType.FRAMEWORKS_SWOT],
      },
      {
        name: TabName.PortersFiveForces,
        items: [RequestType.FRAMEWORKS_PORTERS_FIVE_FORCES],
      },
      {
        name: TabName.AIDA,
        items: [RequestType.FRAMEWORKS_AIDA],
      },
      {
        name: TabName.AARRR,
        items: [RequestType.FRAMEWORKS_AARRR],
      },
      {
        name: TabName.LeanAnalyticsStages,
        items: [RequestType.FRAMEWORKS_LEAN_ANALYTICS_STAGES],
      },
      {
        name: TabName.HOOK,
        items: [RequestType.FRAMEWORKS_HOOK_FRAMEWORK],
      },
      {
        name: TabName.ICEScore,
        items: [RequestType.FRAMEWORKS_ICE_SCORE],
      },
      {
        name: TabName.AnsoffMatrix,
        items: [RequestType.FRAMEWORKS_ANSOFF_MATRIX],
      },
    ],
  },
  {
    name: SectionName.Video,
    icon: IconVideoScripts,
    tabs: [
      {
        name: TabName.YouTubeAdScript,
        items: [RequestType.YOUTUBE_ADS_VIDEO],
      },
      {
        name: TabName.ContentPlan,
        items: [RequestType.YOUTUBE_VIDEO_IDEAS],
      },
      {
        name: TabName.Keywords,
        items: [RequestType.YOUTUBE_KEYWORDS],
      },
      {
        name: TabName.TitlesDescriptions,
        items: [RequestType.YOUTUBE_TITLES_PROMO_VIDEO],
      },
      {
        name: TabName.InstagramReel,
        items: [RequestType.INSTAGRAM_ADS_VIDEO],
      },
      {
        name: TabName.GoogleAds,
        items: [RequestType.GOOGLE_ADS_VIDEO],
      },
      {
        name: TabName.TikTokAd,
        items: [RequestType.TIKTOK_VIDEO],
      },
    ],
  },
];

export const ANIMATION_DURATION_MS = 150;

export const NUMBER_OF_FIELDS_TO_SHOW_NON_AUTH_USERS = 1;
export const NUMBER_OF_TABS_TO_SHOW_NON_AUTH_USERS = 3;

export const WEBSITE_LANGUAGES = ["en", "uk"]; // priority WEBSITE_LANGUAGES, 1st is default

export const OUTPUT_LANGUAGE_DATA: OutputLanguageData[] = [
  { code: "en", name: "English" },
  { code: "uk", name: "Ukrainian" },
  { code: "fr", name: "French" },
  { code: "gb", name: "English (UK)" },
  { code: "af", name: "Afrikaans" },
  { code: "sq", name: "Albanian" },
  { code: "am", name: "Amharic" },
  { code: "ar", name: "Arabic" },
  { code: "hy", name: "Armenian" },
  { code: "az", name: "Azerbaijani" },
  { code: "eu", name: "Basque" },
  { code: "bn", name: "Bengali" },
  { code: "bs", name: "Bosnian" },
  { code: "bg", name: "Bulgarian" },
  { code: "ca", name: "Catalan" },
  { code: "zh", name: "Chinese (Mandarin)" },
  { code: "hr", name: "Croatian" },
  { code: "cs", name: "Czech" },
  { code: "da", name: "Danish" },
  { code: "nl", name: "Dutch" },
  { code: "et", name: "Estonian" },
  { code: "fil", name: "Filipino (Tagalog)" },
  { code: "fi", name: "Finnish" },
  { code: "gl", name: "Galician" },
  { code: "ka", name: "Georgian" },
  { code: "de", name: "German" },
  { code: "el", name: "Greek" },
  { code: "gu", name: "Gujarati" },
  { code: "ht", name: "Haitian Creole" },
  { code: "he", name: "Hebrew" },
  { code: "hi", name: "Hindi" },
  { code: "hu", name: "Hungarian" },
  { code: "is", name: "Icelandic" },
  { code: "id", name: "Indonesian" },
  { code: "ga", name: "Irish" },
  { code: "it", name: "Italian" },
  { code: "ja", name: "Japanese" },
  { code: "kn", name: "Kannada" },
  { code: "kk", name: "Kazakh" },
  { code: "ko", name: "Korean" },
  { code: "ku", name: "Kurdish" },
  { code: "ky", name: "Kyrgyz" },
  { code: "lo", name: "Lao" },
  { code: "lv", name: "Latvian" },
  { code: "lt", name: "Lithuanian" },
  { code: "lb", name: "Luxembourgish" },
  { code: "mk", name: "Macedonian" },
  { code: "mg", name: "Malagasy" },
  { code: "ms", name: "Malay" },
  { code: "ml", name: "Malayalam" },
  { code: "mt", name: "Maltese" },
  { code: "mr", name: "Marathi" },
  { code: "mi", name: "Maori" },
  { code: "mn", name: "Mongolian" },
  { code: "ne", name: "Nepali" },
  { code: "no", name: "Norwegian" },
  { code: "om", name: "Oromo" },
  { code: "ps", name: "Pashto" },
  { code: "fa", name: "Persian (Farsi)" },
  { code: "pl", name: "Polish" },
  { code: "pt", name: "Portuguese" },
  { code: "pa", name: "Punjabi" },
  { code: "ro", name: "Romanian" },
  { code: "sr", name: "Serbian" },
  { code: "si", name: "Sinhala" },
  { code: "sk", name: "Slovak" },
  { code: "sl", name: "Slovenian" },
  { code: "es", name: "Spanish" },
  { code: "sw", name: "Swahili" },
  { code: "sv", name: "Swedish" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "th", name: "Thai" },
  { code: "tr", name: "Turkish" },
  { code: "ur", name: "Urdu" },
  { code: "uz", name: "Uzbek" },
  { code: "vi", name: "Vietnamese" },
  { code: "cy", name: "Welsh" },
  { code: "xh", name: "Xhosa" },
  { code: "yo", name: "Yoruba" },
  { code: "zu", name: "Zulu" },
]; // All languages supported by the OpenAI, default is English

export const IS_DEVELOPMENT_ENV = IS_LOCAL_BASE_URL || IS_DEV_BASE_URL;
export const API_KEY_ENV = IS_DEVELOPMENT_ENV ? API_KEY_DEV : API_KEY_PROD;
const PROPERTY_DEV = "dev";
const PROPERTY_PROD = "prod";
export const PROPERTY_ENV = IS_DEVELOPMENT_ENV ? PROPERTY_DEV : PROPERTY_PROD;
export const STRIPE_URL_MANAGE_PLAN = IS_PROD_BASE_URL
  ? STRIPE_URL_MANAGE_PLAN_PROD
  : STRIPE_URL_MANAGE_PLAN_DEV;

export const USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_TOTAL =
  "open_ai_token_consumption_total";
export const USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_INPUT =
  "open_ai_token_consumption_input";
export const USER_PROPERTY_OPEN_AI_TOKEN_CONSUMPTION_OUTPUT =
  "open_ai_token_consumption_output";
export const USER_PROPERTY_SIGN_IN = "signed_in";
export const USER_PROPERTY_CREDITS_USED = "credits_used";
export const USER_PROPERTY_REGENERATIONS_USED = "regenerations_used";
export const USER_PROPERTY_PLAN_NAME = "plan_name";

export const PLANS_APPSUMO: AppsumoPlan[] = [
  {
    id: 1,
    name: "AppSumo Begin Plan (Lifetime Access)",
    minCodesToRedeem: 1,
    reqPerMonth: 25,
  },
  {
    id: 2,
    name: "AppSumo Advance Plan (Lifetime Access)",
    minCodesToRedeem: 2,
    reqPerMonth: 100,
  },
  {
    id: 3,
    name: "AppSumo Conquer Plan (Lifetime Access)",
    minCodesToRedeem: 4,
    reqPerMonth: 500,
  },
];

export const INITIAL_STATE = {
  creativity: "standard",
  outputLanguage: OUTPUT_LANGUAGE_DATA[0],
  mode: "url",
} as State;

export const AITER_STATE = "AITER_STATE";

export const SECTIONS_NAME_DESCRIPTION = {
  [RequestType.FACEBOOK_AD_TEXT_IMG_IDEAS]: {
    name: "app.tabs.facebook.FACEBOOK_AD_TEXT_IMG_IDEAS.name",
    description: "app.tabs.facebook.FACEBOOK_AD_TEXT_IMG_IDEAS.description",
  },
  [RequestType.FACEBOOK_NEED_SPECIFIC_ADS]: {
    name: "app.tabs.facebook.FACEBOOK_NEED_SPECIFIC_ADS.name",
    description: "app.tabs.facebook.FACEBOOK_NEED_SPECIFIC_ADS.description",
  },
  [RequestType.FACEBOOK_BARRIERS]: {
    name: "app.tabs.facebook.FACEBOOK_BARRIERS.name",
    description: "app.tabs.facebook.FACEBOOK_BARRIERS.description",
  },
  [RequestType.FACEBOOK_POSTS]: {
    name: "app.tabs.facebook.FACEBOOK_POSTS.name",
    description: "app.tabs.facebook.FACEBOOK_POSTS.description",
  },
  [RequestType.FACEBOOK_POST_IDEAS]: {
    name: "app.tabs.facebook.FACEBOOK_POST_IDEAS.name",
    description: "app.tabs.facebook.FACEBOOK_POST_IDEAS.description",
  },
  [RequestType.GOOGLE_ADS_TEXT]: {
    name: "app.tabs.google.GOOGLE_ADS_TEXT.name",
    description: "app.tabs.google.GOOGLE_ADS_TEXT.description",
  },
  [RequestType.GOOGLE_NEED_SPECIFIC_ADS]: {
    name: "app.tabs.google.GOOGLE_NEED_SPECIFIC_ADS.name",
    description: "app.tabs.google.GOOGLE_NEED_SPECIFIC_ADS.description",
  },
  [RequestType.GOOGLE_ADS_BARRIERS]: {
    name: "app.tabs.google.GOOGLE_ADS_BARRIERS.name",
    description: "app.tabs.google.GOOGLE_ADS_BARRIERS.description",
  },
  [RequestType.GOOGLE_ADS_DISPLAY]: {
    name: "app.tabs.google.GOOGLE_ADS_DISPLAY.name",
    description: "app.tabs.google.GOOGLE_ADS_DISPLAY.description",
  },
  [RequestType.GOOGLE_ADS_VIDEO]: {
    name: "app.tabs.google.GOOGLE_ADS_VIDEO.name",
    description: "app.tabs.google.GOOGLE_ADS_VIDEO.description",
  },
  [RequestType.STRATEGY_BARRIERS]: {
    name: "app.tabs.strategy.STRATEGY_BARRIERS.name",
    description: "app.tabs.strategy.STRATEGY_BARRIERS.description",
  },
  [RequestType.STRATEGY_JOBS_TO_BE_DONE]: {
    name: "app.tabs.strategy.STRATEGY_JOBS_TO_BE_DONE.name",
    description: "app.tabs.strategy.STRATEGY_JOBS_TO_BE_DONE.description",
  },
  [RequestType.STRATEGY_CUSTOMER_PERSONA]: {
    name: "app.tabs.strategy.STRATEGY_CUSTOMER_PERSONA.name",
    description: "app.tabs.strategy.STRATEGY_CUSTOMER_PERSONA.description",
  },
  [RequestType.STRATEGY_MARKETING_IDEAS]: {
    name: "app.tabs.strategy.STRATEGY_MARKETING_IDEAS.name",
    description: "app.tabs.strategy.STRATEGY_MARKETING_IDEAS.description",
  },
  [RequestType.STRATEGY_VALUE_PROPOSITION]: {
    name: "app.tabs.strategy.STRATEGY_VALUE_PROPOSITION.name",
    description: "app.tabs.strategy.STRATEGY_VALUE_PROPOSITION.description",
  },
  [RequestType.STRATEGY_POSITIONING]: {
    name: "app.tabs.strategy.STRATEGY_POSITIONING.name",
    description: "app.tabs.strategy.STRATEGY_POSITIONING.description",
  },
  [RequestType.STRATEGY_CONSUMER_DECISION_JOURNEY]: {
    name: "app.tabs.strategy.STRATEGY_CONSUMER_DECISION_JOURNEY.name",
    description:
      "app.tabs.strategy.STRATEGY_CONSUMER_DECISION_JOURNEY.description",
  },
  [RequestType.STRATEGY_BUSINESS_MODEL_CANVAS]: {
    name: "app.tabs.strategy.STRATEGY_BUSINESS_MODEL_CANVAS.name",
    description: "app.tabs.strategy.STRATEGY_BUSINESS_MODEL_CANVAS.description",
  },
  [RequestType.STRATEGY_VALUE_PROPOSITION_CANVAS]: {
    name: "app.tabs.strategy.STRATEGY_VALUE_PROPOSITION_CANVAS.name",
    description:
      "app.tabs.strategy.STRATEGY_VALUE_PROPOSITION_CANVAS.description",
  },
  [RequestType.FRAMEWORKS_STEPPS]: {
    name: "app.tabs.frameworks.FRAMEWORKS_STEPPS.name",
    description: "app.tabs.frameworks.FRAMEWORKS_STEPPS.description",
  },
  [RequestType.FRAMEWORKS_7P_OF_MARKETING]: {
    name: "app.tabs.frameworks.FRAMEWORKS_7P_OF_MARKETING.name",
    description: "app.tabs.frameworks.FRAMEWORKS_7P_OF_MARKETING.description",
  },
  [RequestType.FRAMEWORKS_SWOT]: {
    name: "app.tabs.frameworks.FRAMEWORKS_SWOT.name",
    description: "app.tabs.frameworks.FRAMEWORKS_SWOT.description",
  },
  [RequestType.FRAMEWORKS_PORTERS_FIVE_FORCES]: {
    name: "app.tabs.frameworks.FRAMEWORKS_PORTERS_FIVE_FORCES.name",
    description:
      "app.tabs.frameworks.FRAMEWORKS_PORTERS_FIVE_FORCES.description",
  },
  [RequestType.FRAMEWORKS_AIDA]: {
    name: "app.tabs.frameworks.FRAMEWORKS_AIDA.name",
    description: "app.tabs.frameworks.FRAMEWORKS_AIDA.description",
  },
  [RequestType.FRAMEWORKS_AARRR]: {
    name: "app.tabs.frameworks.FRAMEWORKS_AARRR.name",
    description: "app.tabs.frameworks.FRAMEWORKS_AARRR.description",
  },
  [RequestType.FRAMEWORKS_LEAN_ANALYTICS_STAGES]: {
    name: "app.tabs.frameworks.FRAMEWORKS_LEAN_ANALYTICS_STAGES.name",
    description:
      "app.tabs.frameworks.FRAMEWORKS_LEAN_ANALYTICS_STAGES.description",
  },
  [RequestType.FRAMEWORKS_HOOK_FRAMEWORK]: {
    name: "app.tabs.frameworks.FRAMEWORKS_HOOK_FRAMEWORK.name",
    description: "app.tabs.frameworks.FRAMEWORKS_HOOK_FRAMEWORK.description",
  },
  [RequestType.FRAMEWORKS_ICE_SCORE]: {
    name: "app.tabs.frameworks.FRAMEWORKS_ICE_SCORE.name",
    description: "app.tabs.frameworks.FRAMEWORKS_ICE_SCORE.description",
  },
  [RequestType.FRAMEWORKS_ANSOFF_MATRIX]: {
    name: "app.tabs.frameworks.FRAMEWORKS_ANSOFF_MATRIX.name",
    description: "app.tabs.frameworks.FRAMEWORKS_ANSOFF_MATRIX.description",
  },
  [RequestType.EMAIL_SUBJECT]: {
    name: "app.tabs.email.EMAIL_SUBJECT.name",
    description: "app.tabs.email.EMAIL_SUBJECT.description",
  },
  [RequestType.EMAIL_PROMOTIONAL]: {
    name: "app.tabs.email.EMAIL_PROMOTIONAL.name",
    description: "app.tabs.email.EMAIL_PROMOTIONAL.description",
  },
  [RequestType.EMAIL_MARKETING_TOPICS]: {
    name: "app.tabs.email.EMAIL_MARKETING_TOPICS.name",
    description: "app.tabs.email.EMAIL_MARKETING_TOPICS.description",
  },
  [RequestType.LINKEDIN_AD_TEXT_IMG_IDEAS]: {
    name: "app.tabs.linkedin.LINKEDIN_AD_TEXT_IMG_IDEAS.name",
    description: "app.tabs.linkedin.LINKEDIN_AD_TEXT_IMG_IDEAS.description",
  },
  [RequestType.LINKEDIN_NEED_SPECIFIC_ADS]: {
    name: "app.tabs.linkedin.LINKEDIN_NEED_SPECIFIC_ADS.name",
    description: "app.tabs.linkedin.LINKEDIN_NEED_SPECIFIC_ADS.description",
  },
  [RequestType.LINKEDIN_BARRIERS]: {
    name: "app.tabs.linkedin.LINKEDIN_BARRIERS.name",
    description: "app.tabs.linkedin.LINKEDIN_BARRIERS.description",
  },
  [RequestType.LINKEDIN_POSTS]: {
    name: "app.tabs.linkedin.LINKEDIN_POSTS.name",
    description: "app.tabs.linkedin.LINKEDIN_POSTS.description",
  },
  [RequestType.LINKEDIN_POST_IDEAS]: {
    name: "app.tabs.linkedin.LINKEDIN_POST_IDEAS.name",
    description: "app.tabs.linkedin.LINKEDIN_POST_IDEAS.description",
  },
  [RequestType.INSTAGRAM_AD_TEXT_IMG_IDEAS]: {
    name: "app.tabs.instagram.INSTAGRAM_AD_TEXT_IMG_IDEAS.name",
    description: "app.tabs.instagram.INSTAGRAM_AD_TEXT_IMG_IDEAS.description",
  },
  [RequestType.INSTAGRAM_CAPTIONS]: {
    name: "app.tabs.instagram.INSTAGRAM_CAPTIONS.name",
    description: "app.tabs.instagram.INSTAGRAM_CAPTIONS.description",
  },
  [RequestType.INSTAGRAM_POST_IDEAS]: {
    name: "app.tabs.instagram.INSTAGRAM_POST_IDEAS.name",
    description: "app.tabs.instagram.INSTAGRAM_POST_IDEAS.description",
  },
  [RequestType.INSTAGRAM_BARRIERS]: {
    name: "app.tabs.instagram.INSTAGRAM_BARRIERS.name",
    description: "app.tabs.instagram.INSTAGRAM_BARRIERS.description",
  },
  [RequestType.INSTAGRAM_NEED_SPECIFIC_ADS]: {
    name: "app.tabs.instagram.INSTAGRAM_NEED_SPECIFIC_ADS.name",
    description: "app.tabs.instagram.INSTAGRAM_NEED_SPECIFIC_ADS.description",
  },
  [RequestType.INSTAGRAM_JOKES_AND_STORIES]: {
    name: "app.tabs.instagram.INSTAGRAM_JOKES_AND_STORIES.name",
    description: "app.tabs.instagram.INSTAGRAM_JOKES_AND_STORIES.description",
  },
  [RequestType.INSTAGRAM_HASHTAG]: {
    name: "app.tabs.instagram.INSTAGRAM_HASHTAG.name",
    description: "app.tabs.instagram.INSTAGRAM_HASHTAG.description",
  },
  [RequestType.INSTAGRAM_ADS_VIDEO]: {
    name: "app.tabs.instagram.INSTAGRAM_ADS_VIDEO.name",
    description: "app.tabs.instagram.INSTAGRAM_ADS_VIDEO.description",
  },
  [RequestType.TIKTOK_VIDEO]: {
    name: "app.tabs.tiktok.TIKTOK_VIDEO.name",
    description: "app.tabs.tiktok.TIKTOK_VIDEO.description",
  },
  [RequestType.TIKTOK_CONTENT_STRATEGY]: {
    name: "app.tabs.tiktok.TIKTOK_CONTENT_STRATEGY.name",
    description: "app.tabs.tiktok.TIKTOK_CONTENT_STRATEGY.description",
  },
  [RequestType.TIKTOK_HASHTAGS]: {
    name: "app.tabs.tiktok.TIKTOK_HASHTAGS.name",
    description: "app.tabs.tiktok.TIKTOK_HASHTAGS.description",
  },
  [RequestType.TIKTOK_BIO]: {
    name: "app.tabs.tiktok.TIKTOK_BIO.name",
    description: "app.tabs.tiktok.TIKTOK_BIO.description",
  },
  [RequestType.PR_LIST_OF_MEDIA_TO_USE_FOR_PROMO]: {
    name: "app.tabs.pr.PR_LIST_OF_MEDIA_TO_USE_FOR_PROMO.name",
    description: "app.tabs.pr.PR_LIST_OF_MEDIA_TO_USE_FOR_PROMO.description",
  },
  [RequestType.PR_CAMPAIGN_IDEAS]: {
    name: "app.tabs.pr.PR_CAMPAIGN_IDEAS.name",
    description: "app.tabs.pr.PR_CAMPAIGN_IDEAS.description",
  },
  [RequestType.PR_ARTICLE_IDEAS]: {
    name: "app.tabs.pr.PR_ARTICLE_IDEAS.name",
    description: "app.tabs.pr.PR_ARTICLE_IDEAS.description",
  },
  [RequestType.PR_GUEST_ARTICLE]: {
    name: "app.tabs.pr.PR_GUEST_ARTICLE.name",
    description: "app.tabs.pr.PR_GUEST_ARTICLE.description",
  },
  [RequestType.YOUTUBE_TITLES_PROMO_VIDEO]: {
    name: "app.tabs.youtube.YOUTUBE_TITLES_PROMO_VIDEO.name",
    description: "app.tabs.youtube.YOUTUBE_TITLES_PROMO_VIDEO.description",
  },
  [RequestType.YOUTUBE_VIDEO_IDEAS]: {
    name: "app.tabs.youtube.YOUTUBE_VIDEO_IDEAS.name",
    description: "app.tabs.youtube.YOUTUBE_VIDEO_IDEAS.description",
  },
  [RequestType.YOUTUBE_KEYWORDS]: {
    name: "app.tabs.youtube.YOUTUBE_KEYWORDS.name",
    description: "app.tabs.youtube.YOUTUBE_KEYWORDS.description",
  },
  [RequestType.YOUTUBE_ADS_VIDEO]: {
    name: "app.tabs.youtube.YOUTUBE_ADS_VIDEO.name",
    description: "app.tabs.youtube.YOUTUBE_ADS_VIDEO.description",
  },
  [RequestType.BLOG_IDEAS]: {
    name: "app.tabs.blog.BLOG_IDEAS.name",
    description: "app.tabs.blog.BLOG_IDEAS.description",
  },
  [RequestType.BLOG_PROMO_ARTICLE]: {
    name: "app.tabs.blog.BLOG_PROMO_ARTICLE.name",
    description: "app.tabs.blog.BLOG_PROMO_ARTICLE.description",
  },
  [RequestType.BLOG_KEYWORDS]: {
    name: "app.tabs.blog.BLOG_KEYWORDS.name",
    description: "app.tabs.blog.BLOG_KEYWORDS.description",
  },
  [RequestType.BLOG_FAQ]: {
    name: "app.tabs.blog.BLOG_FAQ.name",
    description: "app.tabs.blog.BLOG_FAQ.description",
  },
  [RequestType.SEO_COMMON_KEYWORDS]: {
    name: "app.tabs.seo.SEO_COMMON_KEYWORDS.name",
    description: "app.tabs.seo.SEO_COMMON_KEYWORDS.description",
  },
  [RequestType.SEO_LONG_TAIL_KEYWORDS]: {
    name: "app.tabs.seo.SEO_LONG_TAIL_KEYWORDS.name",
    description: "app.tabs.seo.SEO_LONG_TAIL_KEYWORDS.description",
  },
  [RequestType.SEO_META_TITLE_AND_DESCRIPTION]: {
    name: "app.tabs.seo.SEO_META_TITLE_AND_DESCRIPTION.name",
    description: "app.tabs.seo.SEO_META_TITLE_AND_DESCRIPTION.description",
  },
  [RequestType.BRAND_GUIDE]: {
    name: "app.tabs.branding.BRAND_GUIDE.name",
    description: "app.tabs.branding.BRAND_GUIDE.description",
  },
};

// Simple SHA-256 encoding of the password
export const DEV_LOGIN_PASS_CODE_SHA_256_ENCODED =
  "5080e1923f596a6cbfdf7d15df61c80fd43edc85b58097d75a7dd530428d9757";
export const DEV_LOGIN_PASS_CODE_INSERTED = "DEV_LOGIN_PASS_CODE_INSERTED";

export const LOCKED_SECTIONS_PER_PLAN: Record<PlanCode, SectionName[]> = {
  ["not_registered"]: [
    SectionName.Ads,
    SectionName.Articles,
    SectionName.Brand,
    SectionName.Video,
    SectionName.Strategy,
    SectionName.FrameworksAndIdeas,
  ],
  ["free"]: [SectionName.Strategy, SectionName.FrameworksAndIdeas],
  ["begin_monthly"]: [SectionName.Strategy, SectionName.FrameworksAndIdeas],
  ["begin_annual"]: [SectionName.Strategy, SectionName.FrameworksAndIdeas],
  ["begin_lifetime"]: [SectionName.Strategy, SectionName.FrameworksAndIdeas],
  ["advance_monthly"]: [],
  ["advance_annual"]: [],
  ["advance_lifetime"]: [],
  ["conquer_monthly"]: [],
  ["conquer_annual"]: [],
  ["conquer_lifetime"]: [],
};
