import { marked } from "marked";

export function markdownToPlainText(markdown: string): string {
  // Convert markdown string to HTML string using marked
  const html: string = <string>marked.parse(markdown);

  // Create a temporary DOM element to insert the HTML content
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Extract and return the text content from the temporary DOM element
  // This effectively removes all HTML tags and returns only the text
  return tempDiv.textContent || tempDiv.innerText || "";
}

export function isTextWithMarkdown(text: string): boolean {
  // Regex to check for Markdown headers
  const isMarkdownHeader = /^\s*(#+)\s.*$/gm;

  // Regex to check for Markdown tables (with headers, divider, and rows)
  const isMarkdownTable =
    /(^\|.*\|\s*$\n?^\|[-:]+[-| :]*$\n?^\|.*\|\s*$)/gm.test(text);

  // Regex to check for Markdown bold text
  const isMarkdownBold = /^.*\*\*.*?\*\*.*/gm.test(text);

  // Return true if any of the Markdown features are detected
  const isMarkdown =
    isMarkdownHeader.test(text) || isMarkdownTable || isMarkdownBold;

  if (text.includes("Transform Your Workflow with Notion Today!**")) {
    console.log(
      `isMarkdown: ${isMarkdown} | isMarkdownHeader: ${isMarkdownHeader.test(
        text
      )} | isMarkdownTable: ${isMarkdownTable} | isMarkdownBold: ${isMarkdownBold}`
    );
    console.log(text);
  }
  return isMarkdown;
}

export function cleanMarkdownAndFormat(text: string): string {
  if (!text.trim()) {
    return "";
  }

  // Replace all Markdown headers with two hashes
  const testCleanStep1 = text.replace(/^#+/gm, "###");

  // Remove all code blocks (lines starting with three backticks)
  const testCleanStep2 = testCleanStep1.replace(/^```.*$/gm, "");

  // remove lines with Marketing brief mention (including the word Marketing brief) in any case
  const testCleanStep3 = testCleanStep2.replace(
    /^### marketing brief.*$/gim,
    ""
  );

  return testCleanStep3;
}

export function postProcessSummary(summary: string) {
  if (!summary.trim()) {
    return "";
  }

  // if summary has markdown, convert ## and # into ###
  if (isTextWithMarkdown(summary)) {
    return cleanMarkdownAndFormat(summary);
  } else {
    // replace "Marketing brief" with "### Marketing Brief" on the first line
    return summary.replace(/^Marketing brief/gm, "### Marketing Brief");
  }
}

// write function to parse url into domain name
export function parseDomainFromUrl(url?: string): string {
  try {
    const urlObj = new URL(url!);
    return urlObj.hostname.replace(/^www\./i, "");
  } catch (error) {
    return "";
  }
}
