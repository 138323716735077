import { Dialog, DialogContent, Divider, IconButton, LinearProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingResultsAnimation from '../LoadingResultsAnimation';
import { IconClose } from '../minicomponents/icons';

interface LoadingProgressPopupProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  initialText?: string;
  isSimpleLoading?: boolean;
}

const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 2,
  backgroundColor: theme.palette.primary.light,
  '& .MuiLinearProgress-bar': {
    borderRadius: 2,
    backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    transition: 'width 0.1s linear',
  },
}));

const LoadingPopup: React.FC<LoadingProgressPopupProps> = ({ open, onClose, initialText = undefined, title = undefined, isSimpleLoading = false }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState<number>(0);
  const [isIndeterminate, setIsIndeterminate] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let interval: NodeJS.Timeout;

    if (open && !isSimpleLoading) {
      setProgress(0);
      setIsIndeterminate(false);

      const totalDuration = 45000;
      const updateInterval = 100;
      const increment = (100 / totalDuration) * updateInterval;

      interval = setInterval(() => {
        setProgress(prev => {
          const newProgress = prev + increment;
          if (newProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return newProgress;
        });
      }, updateInterval);

      timer = setTimeout(() => {
        setIsIndeterminate(true);
      }, totalDuration);
    }

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [open, isSimpleLoading]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: motion.div,
        initial: { y: '100%' },
        animate: { y: 0 },
        exit: { y: '100%' },
        transition: { type: 'spring', stiffness: 300, damping: 30 },
        sx: {
          maxHeight: 'calc(100vh - 104px)',
          marginTop: '52px',
          marginBottom: '52px',
          display: 'flex',
          flexDirection: 'column',
          width: isSimpleLoading ? 400 : undefined,
        },
      }}
      fullWidth={!isSimpleLoading}
      maxWidth="tablet"
      aria-labelledby="loading-progress-title"
      aria-describedby="loading-progress-description">
      {/* Header Section */}
      <Grid container sx={{ p: 3, pb: 2, alignItems: 'center' }}>
        <Grid size={'grow'}>
          <Typography variant="h2">{title ?? t('website.loadingPopup.title')}</Typography>
        </Grid>
        {onClose && (
          <Grid size={'auto'} justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <IconClose />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Divider />

      {/* Main Content */}
      <DialogContent sx={{ px: 3, py: 2 }}>
        <Grid container gap={2}>
          {!isSimpleLoading && (
            <Grid size={12}>
              <LoadingResultsAnimation />
            </Grid>
          )}
          <Grid size={12}>
            {isSimpleLoading ? (
              <Grid sx={{ m: 2 }}>
                <Typography sx={{ m: 2, textAlign: 'center' }}>{initialText ?? t('website.loadingPopup.initialText')}</Typography>
                <LinearProgress />
              </Grid>
            ) : (
              <GradientLinearProgress variant={isIndeterminate ? 'indeterminate' : 'determinate'} value={isIndeterminate ? undefined : Math.min(progress, 100)} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingPopup;
