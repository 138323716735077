import { Alert, Box, Button, TextField } from '@mui/material';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { hashValue } from '../utils/auth';
import { DEV_LOGIN_PASS_CODE_INSERTED, DEV_LOGIN_PASS_CODE_SHA_256_ENCODED } from '../utils/const';

export default function DevLogin() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleLogin = async () => {
    const encoded = await hashValue(password);
    if (encoded === DEV_LOGIN_PASS_CODE_SHA_256_ENCODED) {
      Cookies.set(DEV_LOGIN_PASS_CODE_INSERTED, encoded);
      window.location.href = '/';
    } else {
      setError(true);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}>
      <TextField value={password} onChange={e => setPassword(e.target.value)} sx={{ width: 300 }} placeholder="Enter DEV passcode" onKeyPress={e => e.key === 'Enter' && handleLogin()} />
      {error && (
        <Alert severity="error" sx={{ width: 270 }}>
          Invalid password
        </Alert>
      )}
      <Button variant="contained" onClick={handleLogin} sx={{ width: 300 }}>
        Login
      </Button>
    </Box>
  );
}
