import { Box, Breadcrumbs, Link, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconArrowRight } from './icons';

interface BreadcrumbItem {
  name: string | React.ReactNode;
  path: string;
}

interface BreadcrumbsNavigationProps {
  items: BreadcrumbItem[];
  sx?: SxProps<Theme>;
}

const renderName = (name: string | React.ReactNode, id: string): React.ReactNode => {
  if (React.isValidElement(name)) {
    return <Box key={id}>{name}</Box>;
  } else {
    return (
      <Box key={id}>
        <Typography variant="h1" fontSize={24}>
          {name}
        </Typography>
      </Box>
    );
  }
};

const BreadcrumbsNavigation: React.FC<BreadcrumbsNavigationProps> = ({ items, sx }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={sx} separator={<IconArrowRight sx={{ fontSize: 24, verticalAlign: 'middle', color: 'text.primary' }} />}>
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        return isLast ? (
          renderName(item.name, item.path)
        ) : (
          <Link key={item.path} component={RouterLink} to={item.path} underline="hover" color="inherit">
            {renderName(item.name, item.path)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNavigation;
