import { LoadingButton } from '@mui/lab';
import { SxProps } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import React from 'react';
import { GradientTypography } from './minicomponents';
interface GradientButtonProps {
  disabledTooltipText?: string;
  isLoading?: boolean;
  onSubmitButtonHandler: () => void;
  iconLeft?: React.ReactElement;
  buttonText: string;
  sx?: SxProps<Theme>;
  version?: 'contained' | 'outlined';
}

export const GradientButton: React.FC<GradientButtonProps> = ({ disabledTooltipText, isLoading = false, onSubmitButtonHandler, iconLeft, buttonText, sx, version = 'contained' }) => {
  const theme = useTheme();

  const gradientBackground = `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`;

  const buttonStyles = {
    contained: {
      background: gradientBackground,
      color: 'white',
      '&:hover': {
        opacity: '80%!important',
        transition: 'none!important',
      },
    },
    outlined: {
      background: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      '& .MuiButton-startIcon': {
        color: theme.palette.primary.main,
      },
      '&:hover': {
        background: gradientBackground,
        '& .MuiButton-startIcon': {
          color: 'white',
        },
        '& .MuiTypography-root': {
          color: 'white',
          background: 'none',
          WebkitBackgroundClip: 'unset',
          WebkitTextFillColor: 'white',
        },
      },
    },
  };

  const gradientButton = (
    <LoadingButton
      sx={[
        {
          width: 164,
          ...buttonStyles[version],
          '&.Mui-disabled': {
            background: theme.palette.secondary.light,
            color: theme.palette.primary.contrastText,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      variant={version}
      disabled={isLoading} // Only disable when loading
      onClick={onSubmitButtonHandler}
      startIcon={iconLeft}
      loading={isLoading}>
      {version === 'outlined' ? <GradientTypography variant="button">{buttonText}</GradientTypography> : buttonText}
    </LoadingButton>
  );

  // Always return the button without the Tooltip wrapper
  return gradientButton;
};
