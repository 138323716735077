// translations.ts
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import { initReactI18next } from "react-i18next";

import "moment/dist/locale/uk";

import enTranslation from "../locales/en/translation.json";
import ukTranslation from "../locales/uk/translation.json";

import { WEBSITE_LANGUAGES } from "./const";
import { IS_PROD_BASE_URL } from "./env";

const setMomentLocale = (language: string) => {
  const localeMap: { [key: string]: string } = {
    en: "en",
    uk: "uk",
  };

  const targetLocale = localeMap[language] || language;

  // Check if the locale is available
  if (moment.locales().includes(targetLocale)) {
    moment.locale(targetLocale);
  } else {
    console.warn(
      `Locale ${targetLocale} not available, falling back to English. Available locales: ${moment.locales()}`
    );
    moment.locale("en");
  }
  return moment.locale();
};

// Initialize i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      uk: { translation: ukTranslation },
    },
    fallbackLng: WEBSITE_LANGUAGES,
    debug: !IS_PROD_BASE_URL,
    saveMissing: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    detection: {
      order: ["querystring", "localStorage", "cookie", "navigator"],
      lookupQuerystring: "lng",
      caches: ["localStorage", "cookie"],
    },
  })
  .then(() => {
    console.log("Initial i18n language:", i18n.language);
    setMomentLocale(i18n.language);
  });

// Handle language changes
i18n.on("languageChanged", (lng) => {
  setMomentLocale(lng);
});

export default i18n;
