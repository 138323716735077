import { Avatar, Box, Button, Grid2 as Grid, ListItemIcon, Menu, MenuItem, Skeleton, styled, Typography } from '@mui/material';
import { User } from 'firebase/auth';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import googleLogo from '../../icons/google.svg';
import { trackClickProfile, trackSignOutHeader } from '../../utils/analytics';
import { getCurrentUserAsync, registerAsync, signOutUser } from '../../utils/auth';
import { useFirebaseLoginState } from '../../utils/hooks';
import { IconKeyboardArrowDown, IconLogout, IconPerson } from './icons';

const MenuMaterial = styled(Menu)({
  elevation: 0,
  '& .MuiPaper-root': {
    width: 200,
  },
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: 700,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

const LoadingSkeleton: React.FC = () => (
  <Grid container>
    <Typography variant="body1">
      <Skeleton variant="rectangular" width={180} height={40} />
    </Typography>
  </Grid>
);

const GoogleSignInButton: React.FC = () => {
  const { t } = useTranslation();

  const triggerSignInWithPopup = async () => {
    try {
      await registerAsync();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation={false}
      onClick={triggerSignInWithPopup}
      startIcon={
        <Box sx={{ bgcolor: 'white', width: 28, height: 28, borderRadius: 2 }}>
          <img src={googleLogo} alt="Google Logo" style={{ height: 18, width: 18, paddingLeft: 5, marginRight: 8 }} />
        </Box>
      }
      sx={{
        height: 44,
        borderRadius: 2,
        bgcolor: '#5383ec',
        color: 'primary.contrastText',
        textTransform: 'none',
        elevation: '10px',
        shadow: '10px',
        '&:hover': {
          bgcolor: '#1040ab',
          shadow: 'none',
        },
      }}>
      {t('website.googleAuthButton.signInWithGoogle')}
    </Button>
  );
};

const UserProfileAvatar: React.FC<{ user: User | null }> = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const userPhoto = user?.providerData[0]?.photoURL ?? undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = async () => {
    const user = await getCurrentUserAsync();
    const userId = user?.uid;
    try {
      await signOutUser();
      navigate('/');
      window.location.reload();
    } catch (e) {
      console.error('Error signing out [header]:', e);
    }
    trackSignOutHeader(userId);
  };

  const handleProfileClick = () => {
    trackClickProfile();
    navigate('/app/profile');
  };

  return (
    <Grid container gap={1} alignContent="center" alignItems="center">
      <Grid>
        <Button onClick={handleClick} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} sx={{ pl: 2 }}>
          <Avatar alt="User Avatar" src={userPhoto} />
          <IconKeyboardArrowDown />
        </Button>
      </Grid>
      <MenuMaterial
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <StyledMenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <IconPerson />
          </ListItemIcon>
          {t('website.googleAuthButton.profile')}
        </StyledMenuItem>
        <StyledMenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <IconLogout />
          </ListItemIcon>
          {t('website.googleAuthButton.logout')}
        </StyledMenuItem>
      </MenuMaterial>
    </Grid>
  );
};

export const GoogleAuthProfilePicButton: React.FC = () => {
  const { isRegistered, isAuthStateLoading, user } = useFirebaseLoginState();

  if (isAuthStateLoading) {
    return <LoadingSkeleton />;
  }

  return isRegistered ? <UserProfileAvatar user={user} /> : <GoogleSignInButton />;
};
