import { Autocomplete, Box, styled, TextField, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OUTPUT_LANGUAGE_DATA } from '../../utils/const';
import { setOutputLanguageManuallySelected } from '../../utils/state';
import { RootState } from '../../utils/store';
import { OutputLanguageData } from '../../utils/types';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '40px',
  },
  '& .MuiInputLabel-root': {
    fontWeight: 700,
    ...theme.typography.body1,
    transform: 'translate(14px, -9px) scale(0.75)',
  },
  '& .MuiInputBase-input': {
    ...theme.typography.body1,
  },
  '& .MuiAutocomplete-endAdornment': {
    right: 7,
  },
}));

export function OutputLanguageSelect({
  onOutputLanguageChange,
  initialValue,
}: {
  onOutputLanguageChange: (lang: OutputLanguageData) => void;
  initialValue?: OutputLanguageData; // Add initialValue prop
}) {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.appState);
  const theme = useTheme();

  const { i18n, t } = useTranslation();
  const storedOutputLanguage = state.outputLanguage;
  const isManuallySelected = state.isOutputLanguageManuallySelected;

  const [selectedLang, setSelectedLang] = useState<OutputLanguageData>(
    initialValue || storedOutputLanguage || OUTPUT_LANGUAGE_DATA.find(lang => lang.code === i18n.language) || OUTPUT_LANGUAGE_DATA[0]
  );

  const autocompleteRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const [collapsedWidth, setCollapsedWidth] = useState(150);
  const isTabletUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'));

  useEffect(() => {
    if (!isManuallySelected) {
      const newLang = initialValue || OUTPUT_LANGUAGE_DATA.find(lang => lang.code === i18n.language) || OUTPUT_LANGUAGE_DATA[0];
      setSelectedLang(newLang);
      onOutputLanguageChange(newLang);
    }
  }, [i18n.language, isManuallySelected, onOutputLanguageChange, initialValue]);

  useEffect(() => {
    if (textRef.current && isTabletUp) {
      const textWidth = textRef.current.getBoundingClientRect().width;
      setCollapsedWidth(Math.max(150, textWidth + 70)); // Add some padding
    }
  }, [selectedLang, isTabletUp]);

  const handleChange = (_: React.SyntheticEvent, newValue: OutputLanguageData | null) => {
    if (newValue) {
      setSelectedLang(newValue);
      onOutputLanguageChange(newValue);
      if (!isManuallySelected) {
        dispatch(setOutputLanguageManuallySelected(true));
      }
    }
  };

  return (
    <Box sx={{ textAlign: 'left', minWidth: 190 }}>
      <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
        {t('app.labels.outputLanguageLabel')}
      </Typography>
      <Autocomplete
        ref={autocompleteRef}
        options={OUTPUT_LANGUAGE_DATA}
        autoHighlight
        value={selectedLang}
        onChange={handleChange}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              '& > img': { mr: 2, flexShrink: 0 },
              ...theme.typography.body1,
            }}
            {...props}>
            {option.name}
          </Box>
        )}
        renderInput={params => (
          <StyledTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <span
                  ref={textRef}
                  style={{
                    position: 'absolute',
                    visibility: 'hidden',
                    whiteSpace: 'nowrap',
                  }}>
                  {selectedLang.name}
                </span>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              sx: { ml: 1 },
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        disableClearable
        sx={{ width: '100%' }}
      />
    </Box>
  );
}
