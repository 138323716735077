import Grid from '@mui/material/Grid2'; // Updated import
import { Creativity, OutputLanguageData } from '../utils/types';
import { CreativityLevel } from './minicomponents/CreativityLevel';
import { OutputLanguageSelect } from './minicomponents/OutputLanguageSelect';

interface CreativityAndOutputLanguageSectionProps {
  creativity: Creativity;
  outputLanguage: OutputLanguageData;
  handleCreativityChange: (newCreativity: Creativity) => void;
  handleOutputLanguageChange: (newLanguage: OutputLanguageData) => void;
  mode?: 'default' | 'regenerate';
}

export const CreativityAndOutputLanguageSection: React.FC<CreativityAndOutputLanguageSectionProps> = ({
  creativity,
  outputLanguage,
  handleCreativityChange,
  handleOutputLanguageChange,
  mode = 'default',
}) => {
  return (
    <Grid size={12} container gap={2}>
      <Grid size={{ mobile: 12, tablet: 'auto' }}>
        <CreativityLevel initType={creativity} onSelectedCreativityLvlChange={handleCreativityChange} />
      </Grid>

      {mode === 'default' && (
        <Grid size={{ mobile: 12, tablet: 'auto' }}>
          <OutputLanguageSelect initialValue={outputLanguage} onOutputLanguageChange={handleOutputLanguageChange} />
        </Grid>
      )}
    </Grid>
  );
};
