import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputDataSection } from '../InputDataSection';
import { FieldComponent } from '../minicomponents/FieldComponent';
import { GradientButton } from '../minicomponents/GradientButton';
import { IconClose } from '../minicomponents/icons';

interface RegeneratePopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (remarks: string) => void;
  title: string;
  buttonText: string;
  isRemarksMandatory?: boolean;
}

const RegeneratePopup: React.FC<RegeneratePopupProps> = ({ open, onClose, onSubmit, title, buttonText, isRemarksMandatory = true }) => {
  const [prompt, setPrompt] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    if (prompt.trim().length >= 3 || !isRemarksMandatory) {
      onSubmit(prompt);
      onClose();
    } else {
      setError(t('app.labels.MustBeAtLeast3CharactersLong'));
    }
  }, [prompt, onSubmit, onClose]);

  const handleClose = () => {
    setPrompt('');
    setError(null);
    onClose();
  };

  const handlePromptChange = (value: string) => {
    setPrompt(value);
    if (value.trim().length >= 3 && error) {
      setError(null);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: motion.div,
        initial: { y: '100%' },
        animate: { y: 0 },
        exit: { y: '100%' },
        transition: { type: 'spring', stiffness: 300, damping: 30 },
        sx: {
          maxHeight: 'calc(100vh - 104px)',
          marginTop: '52px',
          marginBottom: '52px',
          display: 'flex',
          flexDirection: 'column',
          width: 672,
        },
      }}>
      <Grid container size={12} sx={{ p: 3, pb: 2, alignItems: 'center' }}>
        <Grid size={'grow'}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid size={'auto'} justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <IconClose />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <DialogContent sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
        <Grid container gap={3}>
          <Grid size={12}>
            <InputDataSection isMoreOptionsForceExpanded={true} popupMode="regenerate" />
          </Grid>
          <Grid size={12}>
            <FieldComponent
              fieldValue={prompt}
              onEdit={handlePromptChange}
              fieldLabel={t('app.labels.remarks')}
              placeholder={t('app.labels.remarksPlaceholder')}
              error={error}
              sx={{ color: 'secondary.text' }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2, gap: 1 }}>
        <GradientButton buttonText={buttonText} sx={{ width: 200 }} onSubmitButtonHandler={handleSubmit} disabledTooltipText={error || ''} />
        <Button onClick={handleClose}>{t('app.buttons.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegeneratePopup;
