import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  setPersistence,
} from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";
import { getRemoteConfig } from "firebase/remote-config";
import {
  IS_LOCAL_BASE_URL,
  IS_PROD_BASE_URL,
  VITE_FIREBASECONFIG_DEV_APIKEY,
  VITE_FIREBASECONFIG_DEV_APPID,
  VITE_FIREBASECONFIG_DEV_AUTHDOMAIN,
  VITE_FIREBASECONFIG_DEV_MEASUREMENTID,
  VITE_FIREBASECONFIG_DEV_MESSAGINGSENDERID,
  VITE_FIREBASECONFIG_DEV_PROJECTID,
  VITE_FIREBASECONFIG_DEV_STORAGEBUCKET,
  VITE_FIREBASECONFIG_PROD_APIKEY,
  VITE_FIREBASECONFIG_PROD_APPID,
  VITE_FIREBASECONFIG_PROD_AUTHDOMAIN,
  VITE_FIREBASECONFIG_PROD_MEASUREMENTID,
  VITE_FIREBASECONFIG_PROD_MESSAGINGSENDERID,
  VITE_FIREBASECONFIG_PROD_PROJECTID,
  VITE_FIREBASECONFIG_PROD_STORAGEBUCKET,
} from "./env";

const firebaseConfigProd = {
  apiKey: VITE_FIREBASECONFIG_PROD_APIKEY,
  authDomain: VITE_FIREBASECONFIG_PROD_AUTHDOMAIN,
  projectId: VITE_FIREBASECONFIG_PROD_PROJECTID,
  storageBucket: VITE_FIREBASECONFIG_PROD_STORAGEBUCKET,
  messagingSenderId: VITE_FIREBASECONFIG_PROD_MESSAGINGSENDERID,
  appId: VITE_FIREBASECONFIG_PROD_APPID,
  measurementId: VITE_FIREBASECONFIG_PROD_MEASUREMENTID,
};

const firebaseConfigDev = {
  apiKey: VITE_FIREBASECONFIG_DEV_APIKEY,
  authDomain: VITE_FIREBASECONFIG_DEV_AUTHDOMAIN,
  projectId: VITE_FIREBASECONFIG_DEV_PROJECTID,
  storageBucket: VITE_FIREBASECONFIG_DEV_STORAGEBUCKET,
  messagingSenderId: VITE_FIREBASECONFIG_DEV_MESSAGINGSENDERID,
  appId: VITE_FIREBASECONFIG_DEV_APPID,
  measurementId: VITE_FIREBASECONFIG_DEV_MEASUREMENTID,
};

export const firebaseConfig = IS_PROD_BASE_URL
  ? firebaseConfigProd
  : firebaseConfigDev;

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebasePerformance = getPerformance(firebaseApp);
export const firestoreDb = getFirestore(firebaseApp);
export const firebaseRemoteConfig = getRemoteConfig(firebaseApp);

setPersistence(firebaseAuth, browserLocalPersistence);

firebaseRemoteConfig.settings.minimumFetchIntervalMillis = IS_LOCAL_BASE_URL
  ? 10000 // 10 seconds
  : 3600000; // 1 hour

declare global {
  interface Window {
    emulatorStarted?: boolean;
  }
}

if (IS_LOCAL_BASE_URL && !window.emulatorStarted) {
  window.emulatorStarted = true;
  connectFirestoreEmulator(firestoreDb, "127.0.0.1", 8888);
  connectAuthEmulator(firebaseAuth, "http://localhost:9999");
}
